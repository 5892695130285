import React from 'react';
import {Col, Collapse, Row} from 'react-bootstrap';
import * as ApplicationFormFields from 'src/application/ApplicationFormFields';
import { ApplicationRowForm } from 'shared/types/application';
import {useFormikContext} from 'formik';
import constants from 'shared/constants';

const colProps = {xxl: 3, xl: 4, sm: 6};

interface ApplicationFormProps {
  disabled?: boolean;
}

export function ApplicationForm (props: ApplicationFormProps) {
  const { disabled = false } = props;

  const formProps = useFormikContext<ApplicationRowForm>();
  const includeCoApplicant = formProps.values.include_co_applicant === true;

  return (
    <div>
      <ApplicationFormPartLoan disabled={disabled} />
      <ApplicationFormPartApplicant disabled={disabled} />
      <ApplicationFormPartIncludeCoApplicant disabled={disabled} />
      <Collapse in={includeCoApplicant} unmountOnExit>
        <div>
          <ApplicationFormPartApplicant disabled={disabled} co />
        </div>
      </Collapse>
    </div>
  );
}

interface ApplicationFormPartLoanProps {
  disabled?: boolean;
}

export function ApplicationFormPartLoan (props: ApplicationFormPartLoanProps) {
  const { disabled } = props;

  const colProps = {xxl: 3, xl: 4, sm: 6};
  const formProps = useFormikContext<ApplicationRowForm>();
  const loanAmount = (formProps.values.loan?.amount?.new || 0) + (formProps.values.loan?.amount?.now || 0);

  return (
    <div>
      <Row>
        <Col {...colProps}>
          <ApplicationFormFields.LoanAmountNow disabled={disabled} />
        </Col>
        <Col {...colProps}>
          <ApplicationFormFields.LoanAmountNew disabled={disabled} />
        </Col>
        <Col {...colProps}>
          <ApplicationFormFields.LoanAmountTotal value={loanAmount} />
        </Col>
        <Col {...colProps}>
          <ApplicationFormFields.LoanRepaymentYears disabled={disabled} />
        </Col>
        <Col {...colProps}>
          <ApplicationFormFields.LoanPurpose disabled={disabled} />
        </Col>
      </Row>
    </div>
  );
}

interface ApplicationFormPartApplicantProps {
  disabled?: boolean;
  co?: boolean;
}

export function ApplicationFormPartApplicant (props: ApplicationFormPartApplicantProps) {
  const { disabled, co } = props;

  const formProps = useFormikContext<ApplicationRowForm>();
  const applicant = formProps.values[co ? 'co_applicant' : 'main_applicant'];

  const employmentType = applicant?.employment_type;
  const untilRequired = constants.application.employment_types_until_required.includes(employmentType || '');
  const employerRequired = constants.application.employment_types_contact_required.includes(employmentType || '');

  return (
    <div>
      <h4 className="mb-0 pb-2 mt-4 border-bottom">
        {!co ? 'Huvudsökandes kontaktuppgifter' : 'Medsökandes kontaktuppgifter'}
      </h4>
      <Row>
        <Col {...colProps}>
          <ApplicationFormFields.ApplicantFirstName disabled={disabled} co={co} />
        </Col>
        <Col {...colProps}>
          <ApplicationFormFields.ApplicantLastName disabled={disabled} co={co} />
        </Col>
        <Col {...colProps}>
          <ApplicationFormFields.ApplicantTelephoneMobile disabled={disabled} co={co} />
        </Col>
        <Col {...colProps}>
          <ApplicationFormFields.ApplicantEmail disabled={disabled} co={co} />
        </Col>
      </Row>
      <h4 className="mb-0 pb-2 mt-4 border-bottom">
        {!co ? 'Huvudsökandes adress' : 'Medsökandes adress'}
      </h4>
      <Row>
        <Col {...colProps}>
          <ApplicationFormFields.ApplicantAddressStreet disabled={disabled} co={co} />
        </Col>
        <Col {...colProps}>
          <ApplicationFormFields.ApplicantAddressCo disabled={disabled} co={co} />
        </Col>
        <Col {...colProps}>
          <ApplicationFormFields.ApplicantAddressPostcode disabled={disabled} co={co} />
        </Col>
        <Col {...colProps}>
          <ApplicationFormFields.ApplicantAddressCity disabled={disabled} co={co} />
        </Col>
      </Row>
      <h4 className="mb-0 pb-2 mt-4 border-bottom">
        {!co ? 'Huvudsökandes civilstånd' : 'Medsökandes civilstånd'}
      </h4>
      <Row>
        <Col {...colProps}>
          <ApplicationFormFields.ApplicantStatus disabled={disabled} co={co} />
        </Col>
        <Col {...colProps}>
          <ApplicationFormFields.ApplicantChildren disabled={disabled} co={co} />
        </Col>
      </Row>
      <h4 className="mb-0 pb-2 mt-4 border-bottom">
        {!co ? 'Huvudsökandes boende' : 'Medsökandes boende'}
      </h4>
      <Row>
        <Col {...colProps}>
          <ApplicationFormFields.ApplicantAddressType disabled={disabled} co={co} />
        </Col>
        <Col {...colProps}>
          <ApplicationFormFields.ApplicantAddressSince disabled={disabled} co={co} />
        </Col>
        <Col {...colProps}>
          <ApplicationFormFields.ApplicantHabitationCost disabled={disabled} co={co} />
        </Col>
      </Row>
      <h4 className="mb-0 pb-2 mt-4 border-bottom">
        {!co ? 'Huvudsökandes sysselsättning' : 'Medsökandes sysselsättning'}
      </h4>
      <Row>
        <Col {...colProps}>
          <ApplicationFormFields.ApplicantEmploymentType disabled={disabled} co={co} />
        </Col>
        <Col {...colProps}>
          <ApplicationFormFields.ApplicantIncome disabled={disabled} co={co} />
        </Col>
        <Col {...colProps}>
          <ApplicationFormFields.ApplicantEmployerSince disabled={disabled} co={co} />
        </Col>
        {untilRequired && (
          <Col {...colProps}>
            <ApplicationFormFields.ApplicantEmployerUntil disabled={disabled} co={co} />
          </Col>
        )}
      </Row>
      {employerRequired && (
        <div>
          <h4 className="mb-0 pb-2 mt-4 border-bottom">
            {!co ? 'Huvudsökandes arbetsgivare' : 'Medsökandes arbetsgivare'}
          </h4>
          <Row>
            <Col {...colProps}>
              <ApplicationFormFields.ApplicantEmployerName disabled={disabled} co={co} />
            </Col>
            <Col {...colProps}>
              <ApplicationFormFields.ApplicantEmployerContact disabled={disabled} co={co} />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

interface ApplicationFormPartIncludeCoApplicantProps {
  disabled?: boolean;
}

export function ApplicationFormPartIncludeCoApplicant (props: ApplicationFormPartIncludeCoApplicantProps) {
  const { disabled } = props;
  return (
    <div className="border rounded p-2 mt-3 px-3 d-flex align-items-center">
      <ApplicationFormFields.IncludeCoApplicant disabled={disabled} innerClassName="m-0" />
    </div>
  );
}
