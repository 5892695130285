

const settings = {
  loanClamps: {
    minYears: 1,
    maxYears: 15,
    minTotal: 10000,
    maxTotal: 400000,
  },
  hasVerifications: true,
  hasVerificationsInBid: true,
  accept: {
    accountNoInput: true,
    accountNoRequired: true,
  },
  change: {
    changedByResubmitting: true,
    amountNewInput: true,
  },
};
export default settings;


