import React, {useState} from 'react';
import OverlaySpinner from 'src/spinners/OverlaySpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import {useQuery, useMutation} from '@tanstack/react-query';
import {Form} from 'react-bootstrap';
import * as api from 'src/api';
import useNotyf from 'src/hooks/useNotyf';
import { errorToMessage } from 'src/utils/error';
import ButtonSpinner from 'src/spinners/ButtonSpinner';

interface BasicTemplate {
  id: string;
  name: string;
}

interface TemplateQuickCreateFormProps {
  category: 'email' | 'letter' | 'sms';
  objectType: string;
  objectId: string;
  searchParams: Record<string, any>;
  onCreated?: () => void;
}

export default function TemplateQuickCreateForm (props: TemplateQuickCreateFormProps) {
  const { searchParams, category, objectId, objectType, onCreated } = props;

  const notyf = useNotyf();

  const query = useQuery<BasicTemplate[], Error>({
    queryKey: [`/template/${category}/search`, {params: searchParams}],
  });

  const createMutation = useMutation<BasicTemplate, Error, BasicTemplate['id']>({
    mutationFn: templateId => api.request({
      url: `/${category}/${objectType}/${objectId}/${templateId}`,
      method: 'POST',
      data: {},
    }),
    onError: err => {
      notyf.error(errorToMessage(err));
    },
    onSuccess: () => {
      notyf.success({type: 'success', message: 'Objektet skapades'});
      onCreated?.();
      setTemplateId('');
    },
  });

  const [templateId, setTemplateId] = useState<string>('');

  const onChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    setTemplateId(ev.target.value);
  };

  const onSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    createMutation.mutate(templateId);
  };

  const data = query.data || [];

  return (
    <form onSubmit={onSubmit}>
      <OverlaySpinner isLoading={query.isLoading || query.isRefetching} />
      <ErrorAlert error={query.error} className="mb-2" />
      <div className="d-flex gap-2">
        <Form.Group className="flex-grow w-100">
          <Form.Select
            name="template_id"
            disabled={!query.isSuccess}
            onChange={onChange}
            value={templateId}
          >
            <option value="">Välj en kontaktmall</option>
            {query.isSuccess && data.map(item => (
              <option value={item.id} key={item.id}>{item.name}</option>
            ))}
          </Form.Select>
        </Form.Group>
        <ButtonSpinner
          isLoading={createMutation.isPending}
          type="submit"
          disabled={!templateId || createMutation.isPending}
          variant="success"
        >
          Skapa
        </ButtonSpinner>
      </div>
    </form>
  );
}
