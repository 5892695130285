

const settings = {
  loanClamps: {
    minYears: 1,
    maxYears: 15,
    minTotal: 20000,
    maxTotal: 600000,
    minTotalClamp: false,
  },
  hasVerifications: true,
  hasVerificationsInBid: true,
  accept: {
    canSelectOffer: true,
    selectBidByKey: 'id',
  },
};
export default settings;
