

const settings = {
  bankInterface: {
    index: {
      defaultFilter: {status: 'open'},
    },
  },
  hasVerifications: true,
  hasVerificationsInBid: true,
  accept: {
    canHaveCoApplicant: true,
  },
  loanClamps: {
    minYears: 1,
    maxYears: 12,
    minTotal: 30000,
    maxTotal: 500000,
    minTotalClamp: false,
  },
};
export default settings;
