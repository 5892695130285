import React from 'react';
import { Spinner, SpinnerProps } from 'react-bootstrap';

interface BasicSpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
  isLoading?: boolean;
  spinnerProps?: SpinnerProps;
}

export default function BasicSpinner (props: BasicSpinnerProps) {
  const { isLoading, spinnerProps = {}, ...otherProps } = props;
  if (!isLoading) return null;
  return (
    <div {...otherProps}>
      <Spinner
        className="position-relative"
        animation="border"
        variant="default"
        size="sm"
        style={{top: '2px'}}
        {...spinnerProps}
      />
    </div>
  );
}


