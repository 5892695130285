const settings = {
  bankInterface: {
    index: {
      defaultFilter: {
        status: 'open',
        sub_status: 'pending,change',
      },
    },
  },
  hasVerifications: true,
  loanClamps: {
    minYears: 2,
    maxYears: 15,
    minTotal: 25000,
    maxTotal: 500000,
    minTotalClamp: false,
  },
  accept: {
  },
  extraActions: ['status'],
};

export default settings;
