import React, { useId, useMemo, useState } from 'react';
import {Formik} from 'formik';
import { Card, Form } from 'react-bootstrap';
import { BankProcessRow } from 'shared/types/bank_process';
import * as formUtils from 'src/utils/form';
import ErrorAlert from 'src/alerts/ErrorAlert';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';
import {GuardPermission} from 'src/guards/AuthGuards';
import * as ApplicationFormHelpers from 'src/application/ApplicationFormHelpers';
import * as api from 'src/api';
import {cloneDeep} from 'lodash';
import {useMutation} from '@tanstack/react-query';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import { BankAdapterValidatorResult } from 'shared/types/bank_adapter';
import ValidationResultTooltipBox from 'src/components/ValidationResultTooltipBox';
import {foldObjToSets} from 'shared/format';
import {FormikBlurSubmitContextProvider} from 'src/form/FormikBlurSubmitContext';
import ConfirmActionModalButton from 'src/buttons/ConfirmActionModalButton';

interface BankProcessValidatorResult extends BankAdapterValidatorResult {
  action: string;
}

interface BankProcessUpdateMutationVars extends Partial<BankProcessRow> {
  'form.loan.amount.now'?: null | number;
  'form.loan.amount.new'?: null | number;
  'form.loan.repayment_years'?: null | number;
  'form.loan.purpose'?: null | string;
  'form.main_applicant.first_name'?: null | string;
  'form.main_applicant.last_name'?: null | string;
  'form.main_applicant.telephone_mobile'?: null | string;
  'form.main_applicant.email'?: null | string;
  'form.main_applicant.status'?: null | string;
  'form.main_applicant.address.since'?: null | string;
  'form.main_applicant.address.type'?: null | string;
  'form.main_applicant.address.street'?: null | string;
  'form.main_applicant.address.co'?: null | string;
  'form.main_applicant.address.postcode'?: null | string;
  'form.main_applicant.address.city'?: null | string;
  'form.main_applicant.habitation_cost'?: null | number;
  'form.main_applicant.children'?: null | number;
  'form.main_applicant.employment_type'?: null | string;
  'form.main_applicant.income'?: null | number;
  'form.main_applicant.employer_since'?: null | string;
  'form.main_applicant.employer_until'?: null | string;
  'form.main_applicant.employer_name'?: null | string;
  'form.main_applicant.employer_contact'?: null | string;
  'form.include_co_applicant'?: null | boolean;
  'form.co_applicant.first_name'?: null | string;
  'form.co_applicant.last_name'?: null | string;
  'form.co_applicant.status'?: null | string;
  'form.co_applicant.telephone_mobile'?: null | string;
  'form.co_applicant.address.since'?: null | string;
  'form.co_applicant.address.type'?: null | string;
  'form.co_applicant.address.street'?: null | string;
  'form.co_applicant.address.co'?: null | string;
  'form.co_applicant.address.postcode'?: null | string;
  'form.co_applicant.address.city'?: null | string;
  'form.co_applicant.email'?: null | string;
  'form.co_applicant.employment_type'?: null | string;
  'form.co_applicant.income'?: null | number;
  'form.co_applicant.employer_since'?: null | string;
  'form.co_applicant.employer_until'?: null | string;
  'form.co_applicant.employer_name'?: null | string;
  'form.co_applicant.employer_contact'?: null | string;
  'form.co_applicant.children'?: null | number;
  'form.co_applicant.ssn'?: null | string;
  'form.co_applicant.habitation_cost'?: null | number;
}

interface BankProcessExecuteSubmitMutationVars {
  bypassValidation?: boolean;
}

interface BankProcessApplicationFormProps {
  bankProcess: BankProcessRow;
}

export default function BankProcessApplicationForm (props: BankProcessApplicationFormProps) {
  const { bankProcess } = props;

  const [bypassValidation, setBypassValidation] = useState<boolean>(false);

  const executeSubmitMutation = useMutation<BankProcessRow, Error, BankProcessExecuteSubmitMutationVars>({
    mutationFn: vars => api.request({
      url: `/bank_process/${bankProcess.id}/submit`,
      method: 'POST',
      data: vars,
    }).then(result => result.process),
  });

  const updateMutation = useMutation<BankProcessRow, Error, BankProcessUpdateMutationVars>({
    mutationFn: vars => api.request({
      url: `/bank_process/${bankProcess.id}`,
      method: 'PATCH',
      data: vars,
    }),
  });

  const validateSubmitMutation = useMutation<BankProcessValidatorResult>({
    mutationFn: () => api.request({
      url: `/bank_process/${bankProcess.id}/validateForAction`,
      method: 'POST',
      data: {
        action: 'submitFormAndTryWithoutCoApplicant',
        ids: [bankProcess.bank_id],
      },
    }),
  });

  const onClickValidateSubmit = async () => {
    await validateSubmitMutation.mutateAsync();
  };

  const onClickExecuteSubmit = async () => {
    await executeSubmitMutation.mutateAsync({bypassValidation});
  };

  const initialValues = useMemo(() => bankProcessToApplicationFormValues(bankProcess), [bankProcess]);

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<BankProcessRow, BankProcessRow['form'], BankProcessUpdateMutationVars>({
    queryDataToFormValues: bankProcessToApplicationFormValues,
    formValuesToMutationVars: values => formValuesToUpdate(initialValues, values),
    mutateAsync: updateMutation.mutateAsync,
  });

  const formDisabled = bankProcess.status !== 'new';

  return (
    <Formik initialValues={initialValues} onSubmit={formCycleHelpers.onSubmit} enableReinitialize validateOnMount>
      {formProps => (
        <Form>
          <FormikBlurSubmitContextProvider onSubmit={formCycleHelpers.onSubmit}>
            <Card.Body className="px-3 py-0 pb-3">
              <ApplicationFormHelpers.ApplicationForm disabled={formDisabled} />
            </Card.Body>
            <Card.Footer className="border-top bg-light">
              <ErrorAlert error={updateMutation.error} />
              <div className="d-flex gap-2 align-items-center flex-wrap">
                <InspectObjectModalButton object={formProps} className="p-2" />
                <GuardPermission permission="admin"></GuardPermission>
                <ValidationResultTooltipBox
                  className="align-items-center d-flex"
                  size="sm"
                  validationResult={validateSubmitMutation.data}
                />
                <ButtonSpinner
                  className="rounded"
                  variant="primary"
                  isLoading={validateSubmitMutation.isPending}
                  disabled={validateSubmitMutation.isPending}
                  onClick={onClickValidateSubmit}
                >
                  Validera ansökan för inskickning
                </ButtonSpinner>
                {bypassValidation ? (
                  <ConfirmActionModalButton
                    type="button"
                    className="rounded"
                    variant="primary"
                    disabled={executeSubmitMutation.isPending || !formProps.isValid || formDisabled}
                    onConfirm={onClickExecuteSubmit}
                    message="Är du säker på att du vill åsidosätta valideringen av denna process?"
                    title="Skicka in ansökan utan validering"
                  >
                    Skicka ansökan till långivaren
                  </ConfirmActionModalButton>
                ) : (
                  <ButtonSpinner
                    className="rounded"
                    variant="primary"
                    isLoading={executeSubmitMutation.isPending}
                    disabled={executeSubmitMutation.isPending || !formProps.isValid || formDisabled}
                    onClick={onClickExecuteSubmit}
                  >
                    Skicka ansökan till långivaren
                  </ButtonSpinner>
                )}
                {!formDisabled && (
                  <BypassValidationCheck
                    bypassValidation={bypassValidation}
                    setBypassValidation={setBypassValidation}
                  />
                )}
              </div>
            </Card.Footer>
          </FormikBlurSubmitContextProvider>
        </Form>
      )}
    </Formik>
  );
}

export function bankProcessToApplicationFormValues (bankProcess: BankProcessRow): BankProcessRow['form'] {
  const { form } = bankProcess;
  return cloneDeep(form);
}

export function formValuesToUpdate (initialValues: BankProcessRow['form'], values: BankProcessRow['form']): BankProcessUpdateMutationVars {
  const initialSet = foldObjToSets(initialValues, 'form.');
  const valueSet = foldObjToSets(values, 'form.');
  const changes = formUtils.allChanges(initialSet, valueSet);
  return changes;
}

interface BypassValidationCheckProps {
  bypassValidation: boolean;
  setBypassValidation: React.Dispatch<React.SetStateAction<boolean>>;
}

function BypassValidationCheck (props: BypassValidationCheckProps) {
  const { bypassValidation, setBypassValidation } = props;

  const onChange: React.ChangeEventHandler<HTMLInputElement> = ev => {
    setBypassValidation(ev.target.checked);
  };

  const id = useId();

  return (
    <Form.Check
      className="mb-0"
      id={id}
      label="Åsidosätt validering"
      name="bypassValidation"
      checked={bypassValidation}
      onChange={onChange}
    />
  );
}
