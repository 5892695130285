import React from 'react';
import CustomerAnchor from 'src/customer/CustomerAnchor';
import ApplicationAnchor from 'src/application/ApplicationAnchor';
import UserAnchor from 'src/user/UserAnchor';
import { CustomerRow } from 'shared/types/customer';
import { UserRow } from 'shared/types/user';
import { ApplicationRow } from 'shared/types/application';
import {BankProcessRow} from 'shared/types/bank_process';
import BankProcessAnchor from 'src/bankProcess/BankProcessAnchor';
import {BankRow} from 'shared/types/bank';
import BankAnchor from 'src/bank/BankAnchor';

export type ObjectTypeAnchorProps = {
  type: 'customer';
  value: null | CustomerRow;
} | {
  type: 'user';
  value: null | UserRow;
} | {
  type: 'application';
  value: null | ApplicationRow;
} | {
  type: 'bankProcess';
  value: null | BankProcessRow;
} | {
  type: 'bank';
  value: null | BankRow;
};

function ObjectTypeAnchor (props: ObjectTypeAnchorProps) {
  const { type, value } = props;
  if (!value) return null;
  if (type === 'customer') return <CustomerAnchor customer={value} />;
  if (type === 'application') return <ApplicationAnchor application={value} />;
  if (type === 'user') return <UserAnchor user={value} />;
  if (type === 'bankProcess') return <BankProcessAnchor bankProcess={value} />;
  if (type === 'bank') return <BankAnchor bank={value} />;
  return null;
}
export default React.memo(ObjectTypeAnchor);
