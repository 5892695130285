

const settings = {
  hasVerifications: true,
  hasVerificationsInBid: true,
  warningHighInterestRate: true,
  loanClamps: {
    minYears: 1,
    maxYears: 6,
    minTotal: 1000,
    maxTotal: 45000,
  },
  accept: {
    canSelectOffer: true,
    canHaveCoApplicant: false,
    selectBidByKey: 'their_id',
  },
  change: {
    amountNewInput: true,
    changedByResubmitting: true,
  },
  extraActions: [
    'fetchStatus',
    'fetchOffers',
  ],
};
export default settings;

