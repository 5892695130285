

const settings = {
  bankInterface: {
    index: {
      defaultFilter: {
        status: 'open',
      },
    },
  },
  hasVerifications: true,
  hasVerificationsInBid: true,
  loanClamps: {
    minYears: 1,
    maxYears: 15,
    minTotal: 20000,
    maxTotal: 500000,
  },
  accept: {
    canHaveCoApplicant: false,
  },
  change: {
  },
};
export default settings;
