import React from 'react';
import OverlaySpinner from 'src/spinners/OverlaySpinner';
import { RefreshObjectButton } from 'src/buttons/IconButtons';
import DateTime from 'src/formatters/DateTime';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import { useQuery } from '@tanstack/react-query';
import { StatusLabel } from 'src/complaint/ComplaintFormatters';
import ComplaintAnchor from 'src/complaint/ComplaintAnchor';
import { ComplaintRow } from 'shared/types/complaint';
import complaintFilter from 'shared/filter/complaint';
import { Card, Table } from 'react-bootstrap';
import { Flag } from 'react-feather';

interface QueryResult {
  complaints: ComplaintRow[];
}

export default function LatestComplaintsCard (props) {

  const query = useQuery<QueryResult>({
    queryKey: ['/dashboard/complaints'],
  });

  return (
    <Card className="flex-fill w-100 border">
      <Card.Header className="pb-1">
        <CardTitleIcon
          title="Klagomål"
          Icon={<Flag size={18} />}
          spinning={query.isRefetching}
        >
          <RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <Card.Body className="p-0 position-relative">
        <ErrorAlert error={query.error} className="mx-3 mt-3 mb-2" />
        <OverlaySpinner isLoading={query.isLoading} />
        <div className="table-responsive">
          <Table striped className="my-0 border-bottom">
            <thead>
              <tr>
                <th>ID</th>
                <th>Status</th>
                <th className="d-none d-lg-table-cell">Skapad</th>
                <th className="d-none d-lg-table-cell">Prioritet</th>
              </tr>
            </thead>
            <tbody>
              {query.isSuccess && query.data.complaints?.map(complaint => (
                <tr key={complaint.id}>
                  <td>
                    <ComplaintAnchor complaint={complaint} />
                  </td>
                  <td className="d-none d-lg-table-cell">
                    <StatusLabel short value={complaint.status} />
                  </td>
                  <td className="d-none d-lg-table-cell">
                    <DateTime value={complaint.created_at} />
                  </td>
                  <td>
                    {complaintFilter.priority(complaint.priority, 'short')}
                  </td>
                </tr>
              ))}
              {!query.data?.complaints?.length && (
                <tr>
                  <td colSpan={4}>Det finns klagomål</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
}
