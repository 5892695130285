const settings = {
  bankInterface: {
    index: {
      defaultFilter: {status: 'open'},
    },
  },
  loanClamps: {
    minYears: 1,
    maxYears: 2,
    minTotal: 3000,
    maxTotal: 27500,
    maxTotalClamp: false,
  },
  accept: {
    canHaveCoApplicant: false,
  },
  change: {
    changedByResubmitting: true,
  },
};
export default settings;

