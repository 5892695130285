import React from 'react';
import { BankProcessRow } from 'shared/types/bank_process';
import AssociatedObjectList from 'src/components/AssociatedObjectList';
import {oldCrmUrl} from 'src/misc';

interface BankProcessAssociatedObjectListProps {
  bankProcess: BankProcessRow;
}

function BankProcessAssociatedObjectList (props: BankProcessAssociatedObjectListProps) {
  const { bankProcess } = props;

  const associatedObjects = [
    bankProcess.customer_id ? {type: 'customer', value: {id: bankProcess.customer_id}, label: 'Huvudsökande'} : null,
    bankProcess.customer_id_co ? {type: 'customer', value: {id: bankProcess.customer_id_co}, label: 'Medsökande'} : null,
    bankProcess.bank_id ? {type: 'bank', value: bankProcess.Bank ?? {id: bankProcess.bank_id}, label: 'Bank'} : null,
  ].filter(v => v);

  const externalLinks = [
    {url: oldCrmUrl(`/application/${bankProcess.application_id}/process/${bankProcess.id}/form`), label: 'Gamla CRM'},
  ].filter(v => v);

  return (
    <AssociatedObjectList
      associatedObjects={associatedObjects}
      externalLinks={externalLinks}
    />
  );
}
export default React.memo(BankProcessAssociatedObjectList);
