import avida_stacc from 'shared/bank_adapter/avida_stacc_settings';
import brocc from 'shared/bank_adapter/brocc_settings';
import banky from 'shared/bank_adapter/banky_settings';
import collector_rest from 'shared/bank_adapter/collector_rest_settings';
import credento from 'shared/bank_adapter/credento_settings';
import facit from 'shared/bank_adapter/facit_settings';
import ferratum from 'shared/bank_adapter/ferratum_settings';
import gf_money from 'shared/bank_adapter/gf_money_settings';
import ica from 'shared/bank_adapter/ica_settings';
import ikano from 'shared/bank_adapter/ikano_settings';
import internal from 'shared/bank_adapter/internal_settings';
import klara from 'shared/bank_adapter/klara_settings';
import kkn from 'shared/bank_adapter/kkn_settings';
import komplett from 'shared/bank_adapter/komplett_settings';
import lendify from 'shared/bank_adapter/lendify_settings';
import loanstep from 'shared/bank_adapter/loanstep_settings';
import lsb from 'shared/bank_adapter/lsb_settings';
import marginalen_rest from 'shared/bank_adapter/marginalen_rest_settings';
import medmera_2022 from 'shared/bank_adapter/medmera_2022_settings';
import moank from 'shared/bank_adapter/moank_settings';
import moneygo from 'shared/bank_adapter/moneygo_settings';
import nordax_soap from 'shared/bank_adapter/nordax_soap_settings';
import northmill from 'shared/bank_adapter/northmill_settings';
import norwegian from 'shared/bank_adapter/norwegian_settings';
import nystartfinans from 'shared/bank_adapter/nystartfinans_settings';
import remember from 'shared/bank_adapter/remember_settings';
import resurs from 'shared/bank_adapter/resurs_settings';
import risicum from 'shared/bank_adapter/risicum_settings';
import saldo from 'shared/bank_adapter/saldo_settings';
import santander_2022 from 'shared/bank_adapter/santander_2022_settings';
import sevenday_rest from 'shared/bank_adapter/sevenday_rest_settings';
import svea from 'shared/bank_adapter/svea_settings';
import thorn from 'shared/bank_adapter/thorn_settings';
import wastgota from 'shared/bank_adapter/wastgota_settings';

export default {
  avida_stacc,
  brocc,
  banky,
  collector_rest,
  credento,
  facit,
  ferratum,
  gf_money,
  ica,
  ikano,
  internal,
  klara,
  kkn,
  komplett,
  lendify,
  loanstep,
  lsb,
  marginalen_rest,
  medmera_2022,
  moank,
  moneygo,
  nordax_soap,
  northmill,
  norwegian,
  nystartfinans,
  remember,
  resurs,
  risicum,
  saldo,
  santander_2022,
  sevenday_rest,
  svea,
  thorn,
  wastgota,
}
