

/**
 * WARNING: This file is used clientside
 */

const getter = require('shared/get');

exports.columnName = function (columnName) {
  switch (columnName) {
    default: return columnName;
    case 'id': return 'ID';
    case 'task': return 'Rutin';
    case 'msg': return 'Parametrar';
    case 'status': return 'Status';
    case 'error': return 'Felresultat';
    case 'result': return 'Resultat';
    case 'log': return 'Logg';
    case 'notify': return 'Underrätta';
    case 'retry_remaining': return 'Omtag kvar';
    case 'retry_delay': return 'Omtagsfördröjning';
    case 'prev_work_id': return 'Föregående';
    case 'first_work_id': return 'Första';
    case 'started_at': return 'Påbörjat';
    case 'started_at_relative': return 'Påbörjat';
    case 'finished_at': return 'Avslutat';
    case 'finished_at_relative': return 'Avslutat';
    case 'time_elapsed': return 'Tidsåtgång';
    case 'created_at': return 'Skapad';
    case 'created_at_relative': return 'Skapad';
    case 'updated_at': return 'Uppdaterad';
    case 'updated_at_relative': return 'Uppdaterad';
    case 'actions': return 'Åtgärder';
    case 'match': return 'Matcha';
    case 'unique_key': return 'Unik nyckel';
  }
};

exports.field = function (work, columnName) {
  const get = (...args) => getter(work, ...args);
  switch (columnName) {
    default: return get(columnName);
    case 'status': return exports.status(get('status'));
  }
};

exports.status = function (status) {
  switch (status) {
    default: return status;
    case 'new': return 'Ny';
    case 'error': return 'Fel';
    case 'done': return 'Utförd';
    case 'retry': return 'Omtag';
  }
};
