

const settings = {
  hasVerifications: false,
  hasVerificationsInBid: false,
  warningHighInterestRate: true,
  loanClamps: {
    minYears: 1,
    maxYears: 6,
    minTotal: 1000,
    maxTotal: 30000,
  },
  accept: {
    canHaveCoApplicant: false,
  },
  change: {
  },
};
export default settings;
