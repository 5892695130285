

const settings = {
  bankInterface: {
    index: {
      defaultFilter: {
        status: 'open',
      },
    },
  },
  hasVerifications: true,
  loanClamps: {
    minYears: 2,
    maxYears: 15,
    minTotal: 30000,
    maxTotal: 500000,
    minTotalClamp: false,
  },
  accept: {
    accountNoInput: true,
  },
  respond: {
    theirRefRequired: true,
  },
};
export default settings;
