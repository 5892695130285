import React from 'react';
import { Form, Card, Row, Col } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { WorkTriggerTemplateRow, WorkTriggerTemplateRowForm } from 'shared/types/work_trigger_template';
import * as constants from 'shared/constants';
import * as workTriggerTemplateFilter from 'shared/filter/work_trigger_template';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';
import ConfirmActionModalButton from 'src/buttons/ConfirmActionModalButton';
import moment from 'moment';
import {
  FormikFormGroupCheck,
  FormikFormGroupControl,
  FormikFormGroupSelect,
} from 'src/form/FormikFormControls';
import JSONObjectInput from 'src/inputs/JSONObjectInput';
import { Copy, Trash } from 'react-feather';
import ButtonPromiseSpinner from 'src/spinners/ButtonPromiseSpinner';
import * as formUtils from 'src/utils/form';

interface WorkTriggerTemplateFormProps {
  initialValues: WorkTriggerTemplateRowForm;
  onSubmit: (values: WorkTriggerTemplateRowForm, helpers: FormikHelpers<WorkTriggerTemplateRowForm>) => Promise<any>;
  onClone?: (values: WorkTriggerTemplateRowForm) => Promise<any>;
  onDelete?: () => Promise<any>;
  submitError?: Error | null;
}

const WorkTriggerTemplateForm: React.FC<WorkTriggerTemplateFormProps> = React.memo(function WorkTriggerTemplateForm (props: WorkTriggerTemplateFormProps) {
  const { initialValues, submitError, onSubmit, onClone, onDelete } = props;

  const onClickSubmitClone = (values: WorkTriggerTemplateRowForm) => {
    if (!onClone) return Promise.resolve();
    return onClone(values);
  };

  const onClickDelete = () => {
    if (!onDelete) return Promise.resolve();
    return onDelete();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <Card.Body className="p-3 pt-2">

            <FormikFormGroupControl
              label="Beskrivning"
              name="description"
              minLength={1}
              maxLength={255}
              required
            />

            <Row>

              <Col>
                <FormikFormGroupControl
                  label="Aktiv från"
                  name="is_active_from"
                  type="date"
                  helpText="Ansökningar skapade från och med"
                  emptyValue={null}
                />
              </Col>

              <Col>
                <FormikFormGroupControl
                  label="Aktiv till"
                  name="is_active_to"
                  type="date"
                  helpText="Ansökningar skapade till och med"
                  emptyValue={null}
                />
              </Col>

            </Row>

            <FormikFormGroupSelect
              label="Objektstyp"
              name="object_type"
              required
            >
              {constants.workTriggerTemplate.objectTypes.map((type: string) => (
                <option key={type} value={type}>{workTriggerTemplateFilter.objectType(type)}</option>
              ))}                  
            </FormikFormGroupSelect>

            <FormikFormGroupCheck
              className="mt-3"
              label="Jobbtriggern är aktiv"
              name="is_active"
              type="checkbox"
            />

            <FormikFormGroupControl
              label="Villkor"
              as={JSONObjectInput}
              name="conditions"
              minLength={0}
            />

            <FormikFormGroupControl
              label="Rutin"
              name="task"
              minLength={1}
              maxLength={255}
              required
            />

            <FormikFormGroupControl
              label="Meddelande"
              as={JSONObjectInput}
              name="msg"
              minLength={0}
            />

            <FormikFormGroupControl
              label="Schemaläggning"
              as={JSONObjectInput}
              name="schedule_options"
              minLength={0}
            />

            <FormikFormGroupCheck
              className="mt-3"
              label="Jobbtriggern kan maskeras av behöriga användare"
              name="is_maskable"
              type="checkbox"
            />

          </Card.Body>
          <Card.Footer className="border-top p-3">
            <ErrorAlert error={submitError} />
            <div className="d-flex gap-2 flex-wrap align-items-center justify-content-between">
              {onDelete && (
                <ConfirmActionModalButton
                  type="button"
                  className="rounded d-flex gap-1"
                  variant="danger"
                  disabled={!formProps.isValid || formProps.isSubmitting}
                  onConfirm={onClickDelete}
                  message="Är du säker på att du vill radera triggermallen?"
                  title="Radera den här triggermallen"
                >
                  <Trash size={18} />
                  Radera
                </ConfirmActionModalButton>
              )}
              <div className="d-flex gap-2 flex-wrap align-items-center justify-content-end">
                <InspectObjectModalButton object={initialValues} />
                {onClone && (
                  <ButtonPromiseSpinner
                    type="button"
                    className="rounded d-flex gap-1"
                    variant="outline-success"
                    disabled={!formProps.isValid || formProps.isSubmitting}
                    onClick={() => onClickSubmitClone(formProps.values)}
                    title="Klona den här triggermallen"
                  >
                    <Copy size={18} />
                    Klona
                  </ButtonPromiseSpinner>
                )}
                <ButtonSpinner
                  type="submit"
                  className="rounded"
                  variant="success"
                  isLoading={formProps.isSubmitting}
                  disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
                >
                  Spara
                </ButtonSpinner>
              </div>
            </div>
          </Card.Footer>
        </Form>
      )}
    </Formik>
  );
});
export default WorkTriggerTemplateForm;

export function workTriggerTemplateToFormValues (obj: WorkTriggerTemplateRow): WorkTriggerTemplateRowForm {
  const { is_active_from, is_active_to } = obj;
  return {
    ...obj,
    is_active_from: is_active_from ? moment(is_active_from).format('YYYY-MM-DD') : '',
    is_active_to: is_active_to ? moment(is_active_to).format('YYYY-MM-DD') : '',
  };
}

export function formValuesToUpdate (initialValues: WorkTriggerTemplateRowForm, values: WorkTriggerTemplateRowForm): Partial<WorkTriggerTemplateRow> {
  const changes = formUtils.changes(initialValues, values);
  const update: Partial<WorkTriggerTemplateRowForm> = {...changes};
  if (typeof changes.is_active_from) update.is_active_from = changes.is_active_from || null;
  if (typeof changes.is_active_to) update.is_active_to = changes.is_active_to || null;
  return update;
}

export function toEmptyFormValues (): WorkTriggerTemplateRowForm {
  return {
    id: '',
    object_type: 'application',
    description: '',
    is_active: false,
    is_active_from: '',
    is_active_to: '',
    task: '',
    conditions: {},
    schedule_options: {},
    msg: {},
    is_maskable: false,
  };
}
