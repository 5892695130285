import React from 'react';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import Content from 'src/ui/Content';
import { Helmet } from 'react-helmet-async';
import * as api from 'src/api';
import { ErrorAlertCardBody, CardTitleIconStatus  } from 'src/cards/CardHelpers';
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import { Card, Tab, Nav, Row, Col } from 'react-bootstrap';
import BlockSpinner from 'src/spinners/BlockSpinner';
import {useMutation, useQuery} from '@tanstack/react-query';
import Breadcrumbs from 'src/components/Breadcrumbs';
import * as IconButtons from 'src/buttons/IconButtons';
import * as CustomerFormatters from 'src/customer/CustomerFormatters';
import {Activity, Briefcase, Edit2, Edit3, Frown, LifeBuoy} from 'react-feather';
import { CustomerRow } from 'shared/types/customer';
import CustomerConsentListCard from 'src/customerConsent/CustomerConsentListCard';
import useNotyf from 'src/hooks/useNotyf';
import CustomerAssociatedObjectList from 'src/customer/CustomerAssociatedObjectList';
import CustomerLogCard from 'src/customer/CustomerLogCard';
import ComplaintListCard from 'src/complaint/ComplaintListCard';
import CustomerApplicationListCard from 'src/customer/CustomerApplicationListCard';
import CustomerPageInsuranceTab from 'src/customer/CustomerPageInsuranceTab';
import CustomerPersonalInfoFormCard from 'src/customer/CustomerPersonalInfoFormCard';
import {GuardPermission} from 'src/guards/AuthGuards';

interface CustomerPageParams {
  customerId: string;
  tab?: string;
}

const CustomerPage: React.FC = React.memo(function CustomerPage () {
  const { customerId, tab = 'form' } = useParams() as unknown as CustomerPageParams;

  const notyf = useNotyf();
  // const queryClient = useQueryClient();
  const navigate = useNavigate();

  const readQuery = useQuery<CustomerRow, Error>({
    queryKey: [`/customer/${customerId}`],
  });

  const customer = readQuery.data;

  const deleteMutation = useMutation<void, Error>({
    mutationFn: () => api.request({
      url: `/customer/${customerId}`,
      method: 'DELETE',
    }),
    onSuccess: () => {
      navigate('/customers');
      notyf.success({type: 'danger', message: 'Kunden raderades'});
    },
  });

  const onClickDelete = () => {
    return deleteMutation.mutateAsync();
  };

  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Kund" />
        <h1>Kund</h1>
        <Breadcrumbs
          crumbs={[
            ['/customers', 'Kunder'],
            [`/customer/${customerId}/form`, customer ? <CustomerFormatters.Name value={customer} /> : customerId],
          ]}
        />
        <BlockSpinner isLoading={readQuery.isLoading} />
        <ErrorAlertCardBody error={readQuery.error} className="mx-0 mt-0 mb-3" />
        {customer && (
          <Card className="border">
            <Card.Header className="border-bottom bg-light">
              <CardTitleIconStatus
                as="h2"
                title={<CustomerFormatters.Name value={customer} />}
                Status={<CustomerFormatters.StatusLabel value={customer.status} className="px-3" />}
                Icon={<LifeBuoy size={16} />}
                spinning={readQuery.isRefetching}
              >
                <GuardPermission permission="customer_delete">
                  <IconButtons.ConfirmModalDeleteButton
                    onConfirm={onClickDelete}
                    message="Är du säker på att du vill radera den här kunden?"
                  />
                </GuardPermission>
                <IconButtons.InspectObjectModalButton object={customer} />
                <IconButtons.CopyObjectIdButton id={customer.id} />
                <IconButtons.CopyCurrentUrlButton />
                <IconButtons.RefreshObjectButton
                  disabled={readQuery.isRefetching}
                  onClick={() => readQuery.refetch()}
                />
              </CardTitleIconStatus>
            </Card.Header>
            {customer && (
              <CustomerAssociatedObjectList customer={customer} />
            )}
            <Tab.Container
              activeKey={tab}
              transition={false}
              unmountOnExit={true}
              mountOnEnter={true}
            >
              <Nav className="nav-tabs pt-3 px-3">
                <Nav.Item>
                  <NavLink className="nav-link" to={`/customer/${customerId}/form`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <Edit2 size={12} />{' '}
                      Formulär
                    </span>
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" to={`/customer/${customerId}/applications`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <Briefcase size={12} />{' '}
                      Ansökningar
                    </span>
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" to={`/customer/${customerId}/complaints`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <Frown size={12} />{' '}
                      Klagomål
                    </span>
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" to={`/customer/${customerId}/insurances`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <LifeBuoy size={12} />{' '}
                      Försäkringar
                    </span>
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" to={`/customer/${customerId}/consent`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <Edit3 size={12} />{' '}
                      Samtycken
                    </span>
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" to={`/customer/${customerId}/log`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <Activity size={12} />{' '}
                      Logg
                    </span>
                  </NavLink>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="form">
                  <Card.Body className="pb-0">
                    <Row>
                      <Col xl={6}>
                        {customer && (
                          <CustomerPersonalInfoFormCard customer={customer} />
                        )}
                      </Col>
                      <Col xl={6}>

                      </Col>
                    </Row>
                  </Card.Body>
                </Tab.Pane>
                <Tab.Pane eventKey="applications">
                  <Row className="px-4 pt-4">
                    <Col xl={6}>
                      <CustomerApplicationListCard
                        customerId={customerId}
                        asRole="main"
                      />
                    </Col>
                    <Col xl={6}>
                      <CustomerApplicationListCard
                        customerId={customerId}
                        asRole="co"
                      />
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="complaints">
                  <ComplaintListCard
                    className="m-4"
                    searchParams={{customer_id: customerId}}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="insurances">
                  <CustomerPageInsuranceTab customerId={customerId} />
                </Tab.Pane>
                <Tab.Pane eventKey="consent">
                  {customer && (
                    <CustomerConsentListCard
                      searchParams={{customer_id: customer.id, order: {created_at: -1}}}
                      className="m-4"
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="log">
                  <div>
                    <CustomerLogCard customerId={customerId} className="m-4" />
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card>
        )}
      </Content>
    </Main>
  );
});
export default CustomerPage;
