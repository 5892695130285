import React from 'react';
import StatusBaseLabel from 'src/formatters/StatusBaseLabel';
import { statusIsActive } from 'shared/filter/misc';
import Id from 'src/formatters/Id';
import isObjectLike from 'lodash/isObjectLike';
import {WorkTriggerTemplateRow} from 'shared/types/work_trigger_template';
import IdShort from 'src/formatters/IdShort';

export function StatusLabel (props) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={value}
      formatter={statusIsActive}
      {...restOfProps}
    />
  );
}

interface NameProps {
  value: WorkTriggerTemplateRow;
  idShort?: boolean;
  onlyId?: boolean;
}

export function Name (props: NameProps) {
  const { value:template, idShort = true, onlyId = false } = props;
  if (!isObjectLike(template)) return null;
  if (template.description && !onlyId) return <>{template.description}</>;
  if (idShort) return <IdShort value={template.id} />;
  return <Id value={template.id} />;
}
