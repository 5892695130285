import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import SimpleModalOpeningButton from 'src/buttons/SimpleModalOpeningButton';
import { BankProcessRow } from 'shared/types/bank_process';
import * as api from 'src/api';
import * as BankProcessFormFields from 'src/bankProcess/BankProcessFormFields';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import moment from 'moment';
import ErrorAlert from 'src/alerts/ErrorAlert';
import {useMutation} from '@tanstack/react-query';
import useModalStateContext from 'src/hooks/useModalStateContext';
import useNotyf from 'src/hooks/useNotyf';

interface FormValues {
  closed_reason: BankProcessRow['closed_reason'];
}

interface BankProcessCloseModalButtonProps {
  bankProcess: BankProcessRow;
}

export default function BankProcessCloseModalButton (props: BankProcessCloseModalButtonProps) {
  const { bankProcess } = props;

  return (
    <SimpleModalOpeningButton
      modalBodyClassName="pt-0 pb-3"
      label="Stäng process"
      variant="outline-secondary"
      modalTitle="Stäng process"
      disabled={bankProcess.status === 'closed'}
    >
      <ModalBodyForm bankProcess={bankProcess}/>
    </SimpleModalOpeningButton>
  );
}

interface ModalBodyFormProps {
  bankProcess: BankProcessRow;
}

function ModalBodyForm (props: ModalBodyFormProps) {
  const { bankProcess } = props;

  const notyf = useNotyf();
  const modalStateContext = useModalStateContext();
  const initialValues: FormValues = {closed_reason: getInitialClosedReason(bankProcess)};

  const mutation = useMutation<BankProcessRow, Error, FormValues>({
    mutationFn: vars => api.request({
      url: `/bank_process/${bankProcess.id}/invoke`,
      method: 'POST',
      data: {method: 'set_status_closed', ...vars},
    }),
    onSuccess: () => {
      const message = `Processen med "${bankProcess.bank_id}" har blivit stängd`;
      notyf.success({type: 'default', message});
      modalStateContext.onHide();
    },
  });

  const onSubmit = async (values: FormValues) => {
    await mutation.mutateAsync(values);
    modalStateContext.onHide();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <BankProcessFormFields.ClosedReason required />
          <div className="d-flex mt-3 gap-2 align-items-center justify-content-end">
            <Button onClick={() => modalStateContext.onHide()} variant="outline-secondary">
              Avbryt
            </Button>
            <ButtonSpinner
              variant="primary"
              type="submit"
              disabled={mutation.isPending || !formProps.isValid || formProps.isSubmitting}
              isLoading={formProps.isSubmitting}
            >
              Utför
            </ButtonSpinner>
          </div>
          <ErrorAlert error={mutation.error} />
        </Form>
      )}
    </Formik>
  );
}

// just some simple heuristics to choose a default closed reason
function getInitialClosedReason (bankProcess: BankProcessRow): BankProcessRow['closed_reason'] {
  if (['accepted', 'disbursed'].includes(bankProcess.status)) return 'bank_rejected_post_accept';
  if (moment(bankProcess.created_at).isBefore(moment().subtract(30, 'days'))) return 'expired';
  if (['waiting', 'open'].includes(bankProcess.status)) return 'bank_rejected';
  return 'user_rejected';
}
