const settings = {
  loanClamps: {
    minYears: 1,
    maxYears: 10,
    minTotal: 1000,
    maxTotal: 20000,
  },
  warningHighInterestRate: true,
  change: {
    enabled: true,
    changedByResubmitting: true,
    amountNewInput: true,
  },
  accept: {
    canHaveCoApplicant: true,
  },
  extraActions: ['status'],
};

export default settings;
