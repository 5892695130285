import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import IdShort from 'src/formatters/IdShort';
import { TaskRow } from 'shared/types/task';
import StatusBaseLabel from 'src/formatters/StatusBaseLabel';
import * as taskUtils from 'src/utils/task';
import {
  AlertOctagon,
  CheckCircle,
  CheckSquare,
  Meh,
  ZapOff,
} from 'react-feather';
// import * as taskFilter from 'shared/filter/task';

export function Name (props) {
  const { value:task } = props;
  if (!isObjectLike(task)) return null;
  const id = task.id;
  return (
    <IdShort value={id} />
  );
}

export function StatusLabel (props) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={value}
      {...restOfProps}
    />
  );
}

export function TemporalStatusLabel (props) {
  const { value, ...restOfProps } = props;
  const task = value as TaskRow;
  const now = new Date();
  const temporalStatus = taskUtils.temporalStatusName(task, now);
  return (
    <StatusBaseLabel
      status={temporalStatus}
      {...restOfProps}
    >
      <TemporalStatusIcon value={temporalStatus} />
    </StatusBaseLabel>
  );
}

interface ITemporalStatusIcon {
  value: string;
}
export function TemporalStatusIcon (props: ITemporalStatusIcon) {
  const { value, ...restOfProps } = props;
  const baseProps = {size: 18};
  switch (value) {
    default: return null;
    case 'completed': return <CheckCircle {...baseProps} {...restOfProps} />;
    case 'failed': return <Meh {...baseProps} {...restOfProps} />;
    case 'upcoming-today': return <AlertOctagon {...baseProps} {...restOfProps} />;
    case 'ongoing': return <AlertOctagon {...baseProps} {...restOfProps} />;
    case 'upcoming-future': return <AlertOctagon {...baseProps} {...restOfProps} />;
    case 'missed': return <AlertOctagon {...baseProps} {...restOfProps} />;
  }
}

interface TaskRowFlags {
  value: TaskRow;
  size?: number;
}
export function TaskFlags (props: TaskRowFlags) {
  const { value:task, size = 14 } = props;
  return (
    <>
      {taskUtils.isCompleted(task) && (
        <abbr title="Avklarad">
          <CheckSquare size={size} />
        </abbr>
      )}{' '}
      {taskUtils.isFailed(task) && (
        <abbr title="Misslyckad">
          <Meh size={size} />
        </abbr>
      )}{' '}
      {!task.for_lottery && (
        <abbr title="Ej lotteriåterkomst">
          <ZapOff size={size} />
        </abbr>
      )}
    </>
  );
}
