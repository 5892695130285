

/**
 * WARNING: This file is used clientside
 */

exports.timezone = 'Europe/Stockholm';

exports.application = {};

exports.application.created_by_roles = [
  'salesman', 
  'advisor', 
  'administrator',
  'portal',
];

exports.application.employment_types = [
  'fast_anstalld',
  'egen_foretagare',
  'tidsbegransad_anstallning',
  'provanstalld',
  // 'studerande',
  // 'arbetslos',
  'pensionar',
  'sjukpensionar',
  'timanstalld',
  // 'annat',
];

// employment types for which we need "contact" and "since" information
exports.application.employment_types_contact_required = [
  'fast_anstalld',
  'egen_foretagare',
  'provanstalld',
  'tidsbegransad_anstallning',
  'timanstalld',
];

// employment types for which we need "until" information (temporary employments)
exports.application.employment_types_until_required = [
  'tidsbegransad_anstallning',
  'provanstalld',
  'timanstalld',
];

/*
 * Valid applicant statuses
 */
exports.application.applicant_statuses = [
  'ensam',
  'sambo',
  'gift',
  'skild',
  'anka',
];

exports.application.address_types = [
  'bostadsratt',
  'villa',
  'hyresratt',
  // 'annat',
  'inneboende',
];

// valid application status types
exports.application.status_types = [
  'new',
  'open',
  'closed',
  'waiting',
  'disbursed',
  'accepted',
  'error',
];

exports.application.loan_purposes = [
  'kreditlosning',
  'motorfordon',
  'renovering',
  'rekreation',
  'ovrigt',
];

exports.application.closed_reasons = [
  'bad_credit',
  'offers_rejected:near_hope',
  'offers_rejected:midterm_hope',
  'offers_rejected:far_hope',
  'no_contact',
  'application_rejected',
  'application_rejected_post_accept',
  'pn_expired',
  'offers_expired',
  'no_submission',
  'sanction_list_match',
];

// derives from application.closed_reasons
// @see Application.prototype.getPortalClosedReason()
exports.application.portal_closed_reasons = [
  'application_rejected',
  'application_rejected_post_accept',
  'offers_expired',
  'offers_rejected',
];

exports.applicationTrigger = {};

exports.applicationTrigger.status = [
  'new',
  'scheduled',
  'executing',
  'ok',
  'error',
  'masked',
];

exports.prospectTrigger = {};

exports.prospectTrigger.status = [
  'new',
  'scheduled',
  'executing',
  'ok',
  'error',
  'masked',
];

exports.workTriggerTemplate = {};

exports.workTriggerTemplate.objectTypes = [
  'application',
  'prospect',
];

exports.bank = {};

// valid bank adapters
exports.bank.adapters = [
  'avida_stacc',
  'banky',
  'brocc',
  'collector_rest',
  'credento',
  'facit',
  'ferratum',
  'gf_money',
  'ica',
  'ikano',
  'internal',
  'klara',
  'kkn',
  'komplett',
  'lendify',
  'loanstep',
  'lsb',
  'marginalen_rest',
  'medmera_2022',
  'moank',
  'moneygo',
  'nordax_soap',
  'northmill',
  'norwegian',
  'nystartfinans',
  'remember',
  'resurs',
  'risicum',
  'saldo',
  'santander_2022',
  'sevenday_rest',
  'svea',
  'thorn',
  'wastgota',
];

exports.bank_process = {};

// valid bank process status types
exports.bank_process.status_types = [
  'new',
  'open',
  'closed',
  'waiting',
  'disbursed',
  'accepted',
  'error',
];

// "complete" list of all sub statuses set by adapters
exports.bank_process.all_sub_status_types = [
  'accept',
  'activated',
  'approve',
  'change',
  'change-reject',
  'change-submit',
  'complement',
  'denied',
  'offer',
  'pending',
  'reject',
  'rejected',
  'submit-delayed',
];

// selectable sub_status in bank interface
exports.bank_process.sub_status_types = [
  'pending,change',
  'pending',
  'change',
  'accept',
];

exports.bank_process.closed_reasons = [
  'user_rejected',
  'bank_rejected',
  'bank_rejected_post_accept',
  'expired',
];

exports.bank_process_bid = {};

exports.bank_process_bid.statusTypes = [
  'active',
  'inactive',
];

exports.pepQuery = {};

exports.pepQuery.statusTypes = [
  'new',
  'error',
  'queued',
  'result',
];

exports.template = {};

exports.template.types = [
  'email',
  'letter',
  'sms',
];

exports.template.belong_types = [
  'bank',
];

exports.template.letter = {};

exports.template.letter.templates = [
  'empty',
  'standard',
  'html',
];

exports.template.email = {};

exports.template.email.categories = [
  'application',
  'complaint',
  'customer_cloud_insurance',
  'customer_jaycom_insurance',
];

exports.template.email.html_layouts = [
  'test.html',
  'mjml.html',
  'mjml_en.html',
];

exports.template.email.html_renderers = [
  'markdown',
  'markdown_inline',
  'mjml',
];

exports.template.sms = {};

exports.template.sms.categories = [
  'application',
  'customer_cloud_insurance',
  'customer_jaycom_insurance',
];

exports.email = {};

// valid email.status
exports.email.status_types = [
  'new',
  'error',
  'queued',
  'sent',
];

exports.letter = {};

// valid letter.status
exports.letter.status_types = [
  'new',
  'error',
  'queued-render',
  'rendered',
  'queued-send',
  'sent',
];

exports.sms = {};

exports.sms.status_types = [
  'new',
  'error',
  'queued-send',
  'sent',
  'delivered',
];

exports.user = {};

exports.user.roles = [
  'salesman', 
  'advisor', 
  'bank', 
  'administrator',
];

exports.user.functions = [
  'centralt_funktionsansvarig',
  'klagomalsansvarig',
  'app',
  'errors',
];

exports.user.login_methods = [
  'password',
  'bankid',
  'password_app_totp',
  'password_email_totp',
];

exports.query = {};

exports.query.truthy = [
  1,
  '1',
  true,
  'true',
];

exports.query.isTruthy = function (val) {
  return exports.query.truthy.includes(val);
};

exports.query.falsy = [
  0,
  '0',
  false,
  'false',
];

exports.query.isFalsy = function (val) {
  return exports.query.falsy.includes(val);
};

exports.query.either = exports.query.truthy.slice(0).concat(exports.query.falsy);

exports.customer = {};

exports.customer.status = [
  'prospect',
  'customer',
  'no_contact',
  'no_answer',
  'near_hope',
  'midterm_hope',
  'far_hope',
  'bad_credit',
  'ignore',
  'coapplicant',
  'blocked',
];

// users with customer_update:status:limited can set these statuses
exports.customer.status_limited = [
  'no_contact',
  'no_answer',
];

// users with only customer_update:status:limited can not unset these statuses
exports.customer.status_privileged = [
  'blocked',
  'ignore',
];

exports.customer.risk = [
  'low',
  'medium',
  'high',
  'extreme',
];

exports.customer.pep_myself_title = [
  'm0',
  'm1',
  'm2',
  'm3',
  'm4',
  'm5',
  'm6',
  'm7',
  'm8',
  'm9',
];

exports.customer.pep_knows_title = [
  'k0',
  'k1',
  'k2',
  'k3',
  'k4',
  'k5',
  'k6',
  'k7',
];

exports.customerLoans = {};

exports.customerLoans.amortizationTypes = ['annuity', 'straight'];
exports.customerLoans.periodLengths = ['month', 'year'];
exports.customerLoans.autoCompletes = ['principal', 'interest_rate', 'period_cost', 'repayment_periods'];
exports.customerLoans.durationTypes = ['repayment_periods', 'started_at_and_repayment_periods_initial', 'end_at'];

exports.customerTryggsamLead = {};

exports.customerTryggsamLead.statusTypes = [
  'new',
  'submitted',
  'success',
  'failed',
  'error',
];

exports.customerJaycomInsurance = {};

exports.customerJaycomInsurance.statusTypes = [
  'new',
  'lead',
  'uninterested',
  'queued',
  'active',
  'cancelled',
  'error',
];

exports.customerJaycomInsurance.insuredAmounts = [
  1500,
  2000,
  2500,
  3000,
  4000,
  5000,
  6000,
  7000,
  8000,
  9000,
  10000,
];

// MUST have the same length as exports.customerJaycomInsurance.insuredAmounts
exports.customerJaycomInsurance.insuredAmountPricePerMonth = [
  139,
  185,
  229,
  274,
  359,
  419,
  489,
  559,
  629,
  689,
  749,
];

exports.customerCloudInsurance = {};

exports.customerCloudInsurance.statusTypes = [
  'new',
  'submitted',
  'created',
  'error',
  'ci_pending',
  'ci_converted',
  'ci_cancelled',
  'ci_declined',
  'ci_expired',
];

exports.complaints = {};

exports.complaints.priority = [
  'low',
  'medium',
  'high',
];

exports.complaints.status = [
  'open',
  'closed',
];

exports.file = {};

// valid file roles
exports.file.roles = [
  'administrator',
  'advisor',
  'salesman',
  'employee',
];

exports.task = {};

// valid task types
exports.task.types = [
  'callback-day',
  'callback-interval',
  'callback-exact',
  'other',
];

exports.uc = {};

// all uc response codes that we know
exports.uc.response_codes = [
  'UNG', '001', 'FRV', '002', 'UTV', '003',
  'ADR', '004', 'BOX', '005', 'FAC', '006',
  'POS', '007', 'SKS', '009', 'SKY', '010',
  '012', '013', 'ASK', '008', 'IDH', '011',
  'OKS', 'OKM',
];

// all selectable languages
exports.languages = [
  'sv',
  'en',
  'sr',
  'ar',
  'es',
];

exports.prospect = {};

exports.prospect.results = [
  'not_submitted_yet',
  'halted_active_application',
  'halted_timeout_application',
  'application_created',
  'halted_blocked',
  'halted_flagged',
  'halted_other',
];

exports.work = {};

exports.work.statusTypes = [
  'new',
  'error',
  'done',
  'retry',
];

exports.customerConsent = {};

exports.customerConsent.methods = [
  'customer_bankid_auth',
  'customer_bankid_sign',
  'customer_other',
  'customer_web',
  'user_bankid_phone_auth',
  'user_bankid_phone_sign',
  'user_other',
  'user_phone',
];

exports.emailRegExp = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
