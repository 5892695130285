

const settings = {
  loanClamps: {
    minYears: 1,
    maxYears: 15,
    minTotal: 10000,
    maxTotal: 500000,
  },
  accept: {
    accountNoInput: true,
  },
  change: {
    amountNewInput: true,
    changedByResubmitting: true,
  },
};
export default settings;

