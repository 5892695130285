import React, {useId} from 'react';
import { useField } from 'formik';
import {
  Form,
  FormCheckProps,
  FormControlProps,
  FormSelectProps,
  InputGroup,
} from 'react-bootstrap';
import { FormCheckType } from 'react-bootstrap/esm/FormCheck';
import {
  inputPropsToFormikValidate,
  inputPropsToValue,
  selectPropsToFormikValidate,
  useFieldExtended,
} from 'src/utils/form';
import BasicSpinner from 'src/spinners/BasicSpinner';
import {useFormikBlurSubmit} from 'src/form/FormikBlurSubmitContext';

export function FormikFormGroupControl (props: FormGroupControlProps) {
  const { name, type, emptyValue } = props;

  const [field, meta] = useFieldExtended({
    name,
    type,
    validate: inputPropsToFormikValidate(props),
    emptyValue,
  });

  return (
    <FormGroupControl
      {...field}
      error={meta.error}
      value={inputPropsToValue(props, field.value)}
      {...props}
    />
  );
}

export function BlurSubmitFormikFormGroupControl (props: FormGroupControlProps) {
  const { name, type, emptyValue } = props;

  const [field, meta] = useFieldExtended({
    name,
    type,
    validate: inputPropsToFormikValidate(props),
    emptyValue,
  });

  const { isLoading, onBlur } = useFormikBlurSubmit(name, field);

  return (
    <FormGroupControl
      {...field}
      error={meta.error}
      value={inputPropsToValue(props, field.value)}
      onBlur={onBlur}
      {...props}
      isLoading={isLoading}
    />
  );
}

export function FormikFormGroupSelect (props: FormGroupSelectProps) {
  const { name, emptyValue } = props;

  const [field, meta] = useFieldExtended({
    name,
    validate: selectPropsToFormikValidate(props),
    emptyValue,
  });

  return (
    <FormGroupSelect
      {...field}
      error={meta.error}
      {...props}
    />
  );
}

export function BlurSubmitFormikFormGroupSelect (props: FormGroupSelectProps) {
  const { name, emptyValue } = props;

  const [field, meta] = useFieldExtended({
    name,
    validate: selectPropsToFormikValidate(props),
    emptyValue,
  });

  const { isLoading, onBlur } = useFormikBlurSubmit(name, field);

  return (
    <FormGroupSelect
      {...field}
      error={meta.error}
      onBlur={onBlur}
      {...props}
      isLoading={isLoading}
    />
  );
}

export function FormikFormInputGroupControl (props: InputGroupControlProps) {
  const { name, type, emptyValue } = props;

  const [field, meta] = useFieldExtended({
    name,
    type,
    validate: inputPropsToFormikValidate(props),
    emptyValue,
  });

  return (
    <InputGroupControl
      {...field}
      error={meta.error}
      value={inputPropsToValue(props, field.value)}
      {...props}
    />
  );
}

export function BlurSubmitFormikFormInputGroupControl (props: InputGroupControlProps) {
  const { name, type, emptyValue } = props;

  const [field, meta] = useFieldExtended({
    name,
    type,
    validate: inputPropsToFormikValidate(props),
    emptyValue,
  });

  const { isLoading, onBlur } = useFormikBlurSubmit(name, field);

  return (
    <InputGroupControl
      {...field}
      error={meta.error}
      value={inputPropsToValue(props, field.value)}
      onBlur={onBlur}
      {...props}
      isLoading={isLoading}
    />
  );
}

export function FormikFormInputGroupSelect (props: InputGroupSelectProps) {
  const { name, emptyValue } = props;

  const [field, meta] = useFieldExtended({
    name,
    validate: selectPropsToFormikValidate(props),
    emptyValue,
  });

  return (
    <InputGroupSelect
      {...field}
      error={meta.error}
      {...props}
    />
  );
}

export function BlurSubmitFormikFormInputGroupSelect (props: InputGroupSelectProps) {
  const { name, emptyValue } = props;

  const [field, meta] = useFieldExtended({
    name,
    validate: selectPropsToFormikValidate(props),
    emptyValue,
  });

  const { isLoading, onBlur } = useFormikBlurSubmit(name, field);

  return (
    <InputGroupSelect
      {...field}
      error={meta.error}
      onBlur={onBlur}
      {...props}
      isLoading={isLoading}
    />
  );
}

export function FormikFormGroupCheck (props: FormGroupCheckProps) {
  const { name, value, type } = props;

  const [field, meta] = useField({
    name,
    value,
    type,
  });

  return (
    <FormGroupCheck
      {...field}
      error={meta.error}
      {...props}
    />
  );
}

export function BlurSubmitFormikFormGroupCheck (props: FormGroupCheckProps) {
  const { name, value, type } = props;

  const [field, meta] = useField({
    name,
    value,
    type,
  });

  const { isLoading, onBlur } = useFormikBlurSubmit(name, field);

  return (
    <FormGroupCheck
      {...field}
      error={meta.error}
      onBlur={onBlur}
      {...props}
      isLoading={isLoading}
    />
  );
}

export interface FormGroupCheckProps extends FormCheckProps {
  error?: string;
  name: string;
  value?: string;
  type?: FormCheckType;
  className?: string;
  innerClassName?: string;
  isLoading?: boolean;
}

export function FormGroupCheck (props: FormGroupCheckProps) {
  const {
    error,
    name,
    label:outerLabel,
    type,
    className,
    innerClassName,
    isLoading,
    ...restOfProps
  } = props;

  const id = useId();

  const label = (
    <div className="d-inline-flex gap-2 align-items-center">
      {outerLabel}
      <BasicSpinner isLoading={isLoading} />
    </div>
  );

  return (
    <Form.Group className={className}>
      <Form.Check
        {...restOfProps}
        label={label}
        className={innerClassName}
        type={type}
        id={id}
        name={name}
        isInvalid={Boolean(error)}
      />
      <Form.Control.Feedback type="invalid">
        {error}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export interface FormGroupSelectProps extends FormSelectProps, React.PropsWithChildren {
  emptyValue?: any;
  error?: string;
  label?: React.ReactNode;
  className?: string;
  name: string;
  required?: boolean;
  isLoading?: boolean;
}

export function FormGroupSelect (props: FormGroupSelectProps) {
  const { name, isLoading, error, children, className, emptyValue, label, ...restOfProps } = props; // eslint-disable-line no-unused-vars

  const id = useId();

  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label className="mb-1 mt-3 d-flex gap-1 align-items-center" htmlFor={id}>
          {label}
          <BasicSpinner isLoading={isLoading} />
        </Form.Label>
      )}
      <Form.Select
        {...restOfProps}
        id={id}
        name={name}
        isInvalid={Boolean(error)}
      >
        {children}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {error}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export interface FormGroupControlProps extends FormControlProps {
  emptyValue?: any;
  error?: string;
  label?: React.ReactNode;
  className?: string;
  name: string;
  type?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  step?: number;
  min?: number | string;
  max?: number | string;
  rows?: number | string;
  helpText?: React.ReactNode;
  isLoading?: boolean;
}

export function FormGroupControl (props: FormGroupControlProps) {
  const { name, error, isLoading, type, className, label, emptyValue, helpText, ...restOfProps } = props; // eslint-disable-line no-unused-vars
  const id = useId();
  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label className="mb-1 mt-3 d-flex gap-1 align-items-center" htmlFor={id}>
          {label}
          <BasicSpinner isLoading={isLoading} />
        </Form.Label>
      )}
      <Form.Control
        {...restOfProps}
        id={id}
        name={name}
        type={type}
        isInvalid={Boolean(error)}
      />
      <Form.Control.Feedback type="invalid">
        {error}
      </Form.Control.Feedback>
      {helpText && (
        <Form.Text>{helpText}</Form.Text>
      )}
    </Form.Group>
  );
}

export interface InputGroupControlProps extends FormGroupControlProps {
  error?: string;
  before?: React.ReactNode;
  after?: React.ReactNode;
}

export function InputGroupControl (props: InputGroupControlProps) {
  const { name, error, isLoading, label, className, type, before, emptyValue, after, ...restOfProps } = props; // eslint-disable-line no-unused-vars
  const id = useId();
  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label className="mb-1 mt-3 d-flex gap-1 align-items-center" htmlFor={id}>
          {label}
          <BasicSpinner isLoading={isLoading} />
        </Form.Label>
      )}
      <InputGroup hasValidation>
        {before}
        <Form.Control
          {...restOfProps}
          type={type}
          id={id}
          name={name}
          isInvalid={Boolean(error)}
        />
        {after}
        <Form.Control.Feedback type="invalid">
          {error}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
}

export interface InputGroupSelectProps extends FormGroupSelectProps {
  before?: React.ReactNode;
  after?: React.ReactNode;
}

export function InputGroupSelect (props: InputGroupSelectProps) {
  const { name, error, label, className, before, after, isLoading, emptyValue, ...restOfProps } = props; // eslint-disable-line no-unused-vars
  const id = useId();
  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label className="mb-1 mt-3 d-flex gap-1 align-items-center" htmlFor={id}>
          {label}
          <BasicSpinner isLoading={isLoading} />
        </Form.Label>
      )}
      <InputGroup hasValidation>
        {before}
        <Form.Select
          {...restOfProps}
          id={id}
          name={name}
          isInvalid={Boolean(error)}
        />
        {after}
        <Form.Control.Feedback type="invalid">
          {error}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
}
