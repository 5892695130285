import React from 'react';
import workScheduledFilter from 'shared/filter/work_scheduled';
import * as TableCells from 'src/tables/TableCells';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as TableCellActions from 'src/tables/TableCellActions';
import { IColumnDefinition, IFilterDefinition } from 'src/tables/Table';
import TablePage from 'src/tables/TablePage';

export default function WorkScheduledTablePage () {
  return (
    <TablePage
      title="Schemalagda jobb"
      pageTitle="Schemalagda jobb"
      columnDefinitions={columnDefinitions}
      filterDefinitions={filterDefinitions}
      routeUrl="/work_scheduled"
      baseQueryUrl="/work_scheduled/table"
      baseQueryKey="WorkScheduledTable"
    />
  );
}

const filterDefinitions: IFilterDefinition[] = [
  commonFilterDefs.string({id: 'id'}),
  commonFilterDefs.string({id: 'task'}),
  commonFilterDefs.string({id: 'unique_key'}),
  commonFilterDefs.json({id: 'match'}),
  commonFilterDefs.dateTimeRange({id: 'created_at'}),
  commonFilterDefs.dateRelative({id: 'created_at_relative'}),
  commonFilterDefs.dateTimeRange({id: 'updated_at'}),
  commonFilterDefs.dateRelative({id: 'updated_at_relative'}),
  commonFilterDefs.dateTimeRange({id: 'scheduled_at'}),
  commonFilterDefs.dateRelative({id: 'scheduled_at_relative'}),
].map(filterDefinition => ({
  title: workScheduledFilter.columnName(filterDefinition.id),
  ...filterDefinition,
}));

const columnDefinitions: IColumnDefinition[] = [
  ...([
    commonColumnDefs.workScheduledId({id: 'id', cellProps: {rowAsObject: true}}),
    commonColumnDefs.cell('task'),
    commonColumnDefs.cell('msg', TableCells.JSONStringifyTableCell, {cellKey: 'msg'}),
    commonColumnDefs.cell('unique_key'),
    commonColumnDefs.cell('retry_remaining'),
    commonColumnDefs.cell('retry_delay'),
    commonColumnDefs.cell('notify', TableCells.JSONStringifyTableCell, {cellKey: 'notify'}),
    commonColumnDefs.wrapSearch(commonColumnDefs.cell('first_work_id', TableCells.BasicCell, {idKey: 'first_work_id'})),
    commonColumnDefs.wrapSearch(commonColumnDefs.cell('prev_work_id', TableCells.BasicCell, {idKey: 'prev_work_id'})),
    commonColumnDefs.datetime({
      id: 'scheduled_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.datetime({
      id: 'created_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
    commonColumnDefs.datetime({
      id: 'updated_at',
      sortable: true,
      groups: ['dates', 'sortable'],
    }),
  ].map(columnDefinition => {
    const { id } = columnDefinition;
    const header = workScheduledFilter.columnName(id);
    const cellValue = work => workScheduledFilter.field(work, id);
    return {
      Cell: ({value}) => <>{value}</>,
      ...columnDefinition,
      cellValue,
      Header: ({value}) => <>{value}</>,
      title: header,
    };
  })),
  commonColumnDefs.actions({}, [
    TableCellActions.InspectRowTableCellAction,
  ]),
];
