import React, {useContext} from 'react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import { NavLink, useNavigate } from 'react-router-dom';
import { BankProcessRowWithRelations } from 'shared/types/bank_process';
import {Card, Nav, Tab, Col, Row, CardProps, Collapse} from 'react-bootstrap';
import {Activity, DollarSign, Edit2, FastForward, Repeat, Settings} from 'react-feather';
import BankProcessLogCard from 'src/bankProcess/BankProcessLogCard';
import { CardTitleIcon, CollapsibleCard } from 'src/cards/CardHelpers';
import * as BankProcessFormatters from 'src/bankProcess/BankProcessFormatters';
import * as IconButtons from 'src/buttons/IconButtons';
import DateTime from 'src/formatters/DateTime';
import * as api from 'src/api';
import ErrorModal from 'src/modals/ErrorModal';
import useNotyf from 'src/hooks/useNotyf';
import {GuardPermission} from 'src/guards/AuthGuards';
import {ApplicationPageContext} from 'src/application/ApplicationPage';
import BankProcessControlPanelForm from 'src/bankProcess/BankProcessControlPanelForm';
import BankProcessInvokeForm from 'src/bankProcess/BankProcessInvokeForm';
import BankProcessApplicationForm from 'src/bankProcess/BankProcessApplicationForm';
import BankProcessAssociatedObjectList from 'src/bankProcess/BankProcessAssociatedObjectList';

interface BankProcessCardProps {
  applicationId: string;
  bankProcess: BankProcessRowWithRelations;
  activeBankProcessId: string;
  activeBankProcessTab: string;
}

export default function BankProcessCard (props: BankProcessCardProps) {
  const { applicationId, bankProcess, activeBankProcessId, activeBankProcessTab } = props;

  const hasOffer = Boolean(bankProcess.data?.offer);

  const applicationPageContext = useContext(ApplicationPageContext);

  const controlPanelOpen = applicationPageContext?.state.controlPanelOpen === 'bankProcess';
  const onToggleControlPanel = (open: boolean) => {
    applicationPageContext?.setState(prevState => ({
      ...prevState,
      controlPanelOpen: open ? 'bankProcess' : false,
    }));
  };

  return (
    <Card className="border rounded">
      <BankProcessNavLink
        applicationId={applicationId}
        bankProcess={bankProcess}
        activeBankProcessId={activeBankProcessId}
        activeBankProcessTab={activeBankProcessTab}
      />
      <Collapse in={activeBankProcessId === bankProcess.id} mountOnEnter unmountOnExit appear>
        <div className="border-top">
          {bankProcess && (
            <BankProcessAssociatedObjectList bankProcess={bankProcess} />
          )}
          <Tab.Container
            activeKey={activeBankProcessTab}
            transition={false}
            unmountOnExit={true}
            mountOnEnter={true}
          >
            <Nav className="nav-tabs pt-3 px-3">

              <Nav.Item>
                <NavLink className="nav-link" to={`/application/${applicationId}/bankProcess/${bankProcess.id}/form`}>
                  <span className="d-inline-flex gap-1 align-items-center">
                    <Edit2 size={12} />{' '}
                    Ansökan
                  </span>
                </NavLink>
              </Nav.Item>

              {hasOffer && (
                <Nav.Item>
                  <NavLink className="nav-link" to={`/application/${applicationId}/bankProcess/${bankProcess.id}/offer`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <DollarSign size={12} />{' '}
                      Erbjudanden
                    </span>
                  </NavLink>
                </Nav.Item>
              )}

              <Nav.Item>
                <NavLink className="nav-link" to={`/application/${applicationId}/bankProcess/${bankProcess.id}/decision`}>
                  <span className="d-inline-flex gap-1 align-items-center">
                    <FastForward size={12} />{' '}
                    Beslut
                  </span>
                </NavLink>
              </Nav.Item>

              <Nav.Item>
                <NavLink className="nav-link" to={`/application/${applicationId}/bankProcess/${bankProcess.id}/log`}>
                  <span className="d-inline-flex gap-1 align-items-center">
                    <Activity size={12} />{' '}
                    Logg
                  </span>
                </NavLink>
              </Nav.Item>

            </Nav>

            <Row className="px-4 pt-4">
              <Col lg={controlPanelOpen ? 8 : undefined}>

                <Tab.Content>

                  <Tab.Pane eventKey="form">
                    <Card className="border">
                      <Card.Header className="border-bottom bg-light">
                        <CardTitleIcon
                          title="Ansökan"
                          Icon={<Edit2 size={18} />}
                        />
                      </Card.Header>
                      <BankProcessApplicationForm bankProcess={bankProcess} />
                    </Card>
                  </Tab.Pane>

                  {hasOffer && (
                    <Tab.Pane eventKey="offer">
                      <Card className="border">
                        <Card.Header className="border-bottom bg-light">
                          <CardTitleIcon
                            title="Erbjudanden"
                            Icon={<DollarSign size={18} />}
                          />
                        </Card.Header>
                        <Card.Body>Det finns ingenting här än.</Card.Body>
                      </Card>
                    </Tab.Pane>
                  )}

                  <Tab.Pane eventKey="decision">
                    <Card className="border">
                      <Card.Header className="border-bottom bg-light">
                        <CardTitleIcon
                          title="Beslut"
                          Icon={<FastForward size={18} />}
                        />
                      </Card.Header>
                      <Card.Body>Det finns ingenting här än.</Card.Body>
                    </Card>
                  </Tab.Pane>

                  <Tab.Pane eventKey="log">
                    <BankProcessLogCard bankProcessId={bankProcess.id} />
                  </Tab.Pane>

                </Tab.Content>

              </Col>
              <Col lg={controlPanelOpen ? 4 : 'auto'}>
                <BankProcessControlPanelCard
                  controlPanelOpen={controlPanelOpen}
                  onToggleControlPanel={onToggleControlPanel}
                  className="border"
                  bankProcess={bankProcess}
                />
              </Col>
            </Row>

          </Tab.Container>
        </div>
      </Collapse>
    </Card>
  );
}

interface BankProcessNavLinkProps {
  applicationId: string;
  bankProcess: BankProcessRowWithRelations;
  activeBankProcessId?: string;
  activeBankProcessTab?: string;
}

function BankProcessNavLink (props: BankProcessNavLinkProps) {
  const { applicationId, activeBankProcessId, activeBankProcessTab, bankProcess } = props;

  const notyf = useNotyf();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const deleteMutation = useMutation({
    mutationFn: () => api.request({
      url: `/bank_process/${bankProcess.id}`,
      method: 'DELETE',
    }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: [`/application/${applicationId}/process`]});
      const message = `Långivarprocessen med "${bankProcess.Bank.name ?? bankProcess.bank_id}" har raderats`;
      notyf.success({message, type: 'danger'});
      navigate(`/application/${applicationId}/bankProcess`);
    },
  });

  const onClickDelete = async () => {
    await deleteMutation.mutateAsync();
  };

  const activeBankProcessIsThis = bankProcess.id === activeBankProcessId;
  const to = activeBankProcessIsThis ? `/application/${applicationId}/bankProcess` : `/application/${applicationId}/bankProcess/${bankProcess.id}/${activeBankProcessTab || 'form'}`;

  return (
    <div className="bg-light card-header d-flex justify-content-center justify-content-md-between flex-wrap">
      {deleteMutation.error && <ErrorModal error={deleteMutation.error} />}
      <NavLink className="text-reset text-decoration-none flex-grow-1" to={to}>
        <Card.Title as="span" className="d-flex align-items-center justify-content-center justify-content-md-start gap-2 m-0 p-0 btn btn-link">
          <Repeat size={16} />
          {bankProcess.Bank.name ?? bankProcess.bank_id}
        </Card.Title>
      </NavLink>
      <div className="d-flex justify-content-md-end justify-content-center gap-3 flex-wrap align-items-center">
        <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-md-start">
          <DateTime
            title="Datum för statusuppdatering"
            value={bankProcess.status_updated_at}
            className="small"
            style={{top: '1px', position: 'relative'}}
          />
          <GuardPermission permission="bank_process_delete">
            <IconButtons.ConfirmModalDeleteButton
              onConfirm={onClickDelete}
              message="Är du säker på att du vill radera den här långivarprocessen?"
            />
          </GuardPermission>
          <IconButtons.FavoriteButton type="bankProcess" id={bankProcess.id} />
          <IconButtons.InspectObjectModalButton object={bankProcess} />
          <IconButtons.CopyObjectIdButton id={bankProcess.id} />
        </div>
        <BankProcessFormatters.StatusLabel style={{minWidth: '150px'}} value={bankProcess.status} short />
      </div>
    </div>
  );
}

interface BankProcessControlPanelCardProps extends CardProps {
  bankProcess: BankProcessRowWithRelations;
  controlPanelOpen: boolean;
  onToggleControlPanel: (open: boolean) => void;
}

function BankProcessControlPanelCard (props: BankProcessControlPanelCardProps) {
  const { bankProcess, controlPanelOpen, onToggleControlPanel, ...restOfProps } = props;

  return (
    <CollapsibleCard
      {...restOfProps}
      title="Kontrollpanel"
      Icon={<Settings size={18} />}
      open={controlPanelOpen}
      onToggle={onToggleControlPanel}
    >
      <GuardPermission permission="bank_process_execute:invoke">
        <Card.Body className="border-bottom">
          <BankProcessInvokeForm bankProcess={bankProcess} />
        </Card.Body>
      </GuardPermission>
      <BankProcessControlPanelForm bankProcess={bankProcess} />
    </CollapsibleCard>
  );
}
