import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import { IColumnDefinition, TTableParams } from 'src/tables/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BasicCell } from 'src/tables/TableCells';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import { IStateOrder } from 'src/tables/types';
import * as tableHelpers from 'src/tables/helpers';

export interface ITableHeadTh {
  order?: IStateOrder;
  columnDefinition: IColumnDefinition;
  allRowsSelected?: boolean;
  anyRowsSelected?: boolean;
  setOrder?: (newOrder: IStateOrder) => void;
  onChangeAllSelected?: (ev: ChangeEvent<HTMLInputElement>) => void;
  params?: TTableParams;
}

const TableHeadTh: React.FC<ITableHeadTh> = React.memo(function TableHeadTh (props: ITableHeadTh) {
  const {
    columnDefinition,
    order = {},
    setOrder = () => {},
    allRowsSelected = false,
    anyRowsSelected = false,
    onChangeAllSelected = () => {},
  } = props;
  if (!columnDefinition) return null;
  const {
    id,
    sortable = false,
    title,
    Header = BasicCell,
    headerProps = {},
  } = columnDefinition;

  const sorted = order[columnDefinition.id] || false;

  const onClickToSort = !sortable ? undefined : () => {
    const newSorted = tableHelpers.cycleSorted(sorted);
    const order = {[columnDefinition.id]: newSorted};
    setOrder(order);
  };

  const className = classNames('text-nowrap', {'cursor-pointer select-none': sortable, 'text-end': id === 'actions'});

  return (
    <th>
      <div className={className} onClick={onClickToSort}>
        {Header && (
          <Header
            value={title || id}
            allRowsSelected={allRowsSelected}
            anyRowsSelected={anyRowsSelected}
            onChangeAllSelected={onChangeAllSelected}
            {...headerProps}
          />
        )}
        {sortable && (
          <>
            {sorted === 'desc' && <FontAwesomeIcon icon={faSortDown} className="ms-2" />}
            {sorted === 'asc' && <FontAwesomeIcon icon={faSortUp} className="ms-2" />}
            {sorted === false && <FontAwesomeIcon icon={faSort} className="ms-2" />}
          </>
        )}
      </div>
    </th>
  );
});
export default TableHeadTh;
