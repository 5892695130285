import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import IdShort from 'src/formatters/IdShort';
import * as insuranceFilter from 'shared/filter/customer_cloud_insurance';
import StatusBaseLabel from 'src/formatters/StatusBaseLabel';

export function Name (props) {
  const { value:insurance } = props;
  if (!isObjectLike(insurance)) return null;
  const id = insurance.id;
  return (
    <IdShort value={id} />
  );
}

export function Status (props) {
  const { value } = props;
  return insuranceFilter.status(value);
}

export function StatusLabel (props) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={value}
      formatter={insuranceFilter.status}
      {...restOfProps}
    />
  );
}

