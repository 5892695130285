import React from 'react';
import get from 'lodash/get';
import * as TableCells from 'src/tables/TableCells';
import { IColumnCellProps } from 'src/tables/Table';
import { TableRow } from 'src/tables/types';

interface CommonDefinitionBase {
  id: string;
  [key: string]: any;
}

type CommonDefinitionWithDefaultId = Record<string, any>;

export const cell = (id: string, Cell: React.ComponentType<IColumnCellProps> = TableCells.BasicCell, cellProps = {}) => ({
  id,
  Cell,
  cellProps,
  cellValue: row => get(row, id),
});

export const basic = (extendWith: CommonDefinitionBase) => ({
  Cell: ({value}) => <>{value}</>,
  cellValue: row => get(row, extendWith.id),
  ...extendWith,
});

export const inputMutator = (extendWith: CommonDefinitionBase) => ({
  cellProps: {...(extendWith.cellProps || {})},
  Cell: TableCells.InputMutator,
  ...extendWith,
});

// checkbox select multiple rows
export const select = (extendWith: CommonDefinitionWithDefaultId = {}) => ({
  id: 'select',
  title: 'Markera',
  Header: TableCells.TableSelectHeader,
  Cell: TableCells.TableSelect,
  ...extendWith,
});

// radio select one row
export const selectOne = (extendWith: CommonDefinitionWithDefaultId = {}) => ({
  id: 'select',
  title: 'Välj',
  Cell: TableCells.TableSelectOneRowCell,
  ...extendWith,
});

export const datetime = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.DateTimeTableCell,
  ...extendWith,
});

export const interval = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.IntervalTableCell,
  ...extendWith,
});

export const boolean = (extendWith: CommonDefinitionBase) => basic({
  cellOriginal: (row: TableRow) => get(row, extendWith.id),
  Cell: TableCells.BooleanTableCell,
  ...extendWith,
});

export const booleanNull = (extendWith: CommonDefinitionBase) => basic({
  cellOriginal: (row: TableRow) => get(row, extendWith.id),
  Cell: TableCells.BooleanTableCell,
  cellProps: {strict: true},
  ...extendWith,
});

interface ICommonDefinitionWrappableCell {
  id: string;
  Cell: React.ComponentType<IColumnCellProps>;
  [key: string]: any;
}

export const wrapSearch = (def: ICommonDefinitionWrappableCell) => {
  const { Cell, cellProps = {}, id, ...restOfProps } = def;
  return {
    id,
    cellProps: {...cellProps, InnerCell: Cell},
    Cell: TableCells.WrapSearchTableCell,
    ...restOfProps,
  };
};

export const sumCurrency = ({id, rowKey, ...extendWith}) => ({
  id,
  // Cell: TableCells.Currency, // not needed, usually formatted in shared filter
  cellValue: row => get(row, rowKey || id),
  cellOriginal: row => get(row, rowKey || id),
  Footer: props => {
    const { rows, selectedRows, anyRowsSelected } = props;
    const sum = rows.reduce((sum, row: TableRow) => {
      if (!anyRowsSelected) return sum + get(row, rowKey || id);
      return selectedRows[row.id] ? sum + get(row, rowKey || id) : sum;
    }, 0);
    return (
      <TableCells.Currency {...props} value={sum} />
    );
  },
  ...extendWith,
});

type ActionComponent = React.ComponentType<IColumnCellProps>;

export const actions = (extendWith: CommonDefinitionWithDefaultId = {}, actionComponents: ActionComponent[]) => ({
  id: 'actions',
  title: 'Åtgärder',
  Cell: (props: IColumnCellProps) => (
    <div className="d-flex gap-1 justify-content-end">
      {actionComponents.map((Component, index) => <Component {...props} key={index} />)}
    </div>
  ),
  ...extendWith,
});

export const uuid = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.PepQueryTableCell,
  ...extendWith,
});

export const pepQueryId = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.PepQueryTableCell,
  ...extendWith,
});

export const workId = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.WorkTableCell,
  ...extendWith,
});

export const workScheduledId = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.WorkScheduledTableCell,
  ...extendWith,
});

export const complaintId = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.ComplaintTableCell,
  ...extendWith,
});

export const taskId = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.TaskTableCell,
  ...extendWith,
});

export const applicationId = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.ApplicationTableCell,
  ...extendWith,
});

export const workTriggerTemplateId = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.WorkTriggerTemplateTableCell,
  ...extendWith,
});

export const customerConsentId = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.CustomerConsentTableCell,
  ...extendWith,
});

export const customerCloudInsuranceId = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.CustomerCloudInsuranceTableCell,
  ...extendWith,
});

export const customerJaycomInsuranceId = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.CustomerJaycomInsuranceTableCell,
  ...extendWith,
});

export const templateId = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.TemplateTableCell,
  ...extendWith,
});

export const smsId = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.SmsTableCell,
  ...extendWith,
});

export const emailId = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.EmailTableCell,
  ...extendWith,
});

export const letterId = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.LetterTableCell,
  ...extendWith,
});

export const ucId = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.UcTableCell,
  ...extendWith,
});

export const prospectId = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.ProspectTableCell,
  ...extendWith,
});

export const customerId = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.CustomerTableCell,
  ...extendWith,
});

export const bankProcessId = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.BankProcessTableCell,
  ...extendWith,
});

export const fileId = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.FileTableCell,
  ...extendWith,
});

export const bankId = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.BankTableCell,
  ...extendWith,
});

export const userId = (extendWith: CommonDefinitionBase) => basic({
  Cell: TableCells.UserTableCell,
  ...extendWith,
});

export const foreignId = (id: string, foreignKeyMap: TableCells.IForeignIdTableCellKeyMap) => basic({
  id,
  Cell: TableCells.ForeignIdTableCell,
  cellProps: {foreignKeyMap},
});
