import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import * as pepQueryFilter from 'shared/filter/pep_query';
import StatusBaseLabel from 'src/formatters/StatusBaseLabel';

export function Name (props) {
  const { value:pepQuery } = props;
  if (!isObjectLike(pepQuery)) return null;
  const { id } = pepQuery;
  return (
    <>{id}</>
  );
}

export function Status (props) {
  const { value } = props;
  return pepQueryFilter.status(value);
}

export function StatusLabel (props) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={value}
      formatter={pepQueryFilter.status}
      {...restOfProps}
    />
  );
}

