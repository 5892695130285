

const settings = {
  hasVerifications: true,
  hasVerificationsInBid: false,
  loanClamps: {
    minYears: 1,
    maxYears: 10,
    minTotal: 10000,
    maxTotal: 300000,
  },
  accept: {
    canHaveCoApplicant: false,
    accountNoInput: true,
    accountNoRequired: true,
  },
  change: {
    enabled: false,
  },
};
export default settings;

