import React, {useState} from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ErrorType, errorToMessage } from 'src/utils/error';

interface ErrorModalProps {
  error?: ErrorType | null;
  title?: string;
}

// NOTE! Required to conditionally render ErrorModal (unlike ErrorAlert)
// this ensures that a new modal is rendered on new errors (from i.e. the same query/mutation)
// when the first modal was closed
export default function ErrorModal (props: ErrorModalProps) {
  const { error, title } = props;
  const [show, setShow] = useState<boolean>(true);
  if (!error) return null;
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
    >
      {title && (
        <Modal.Header className="justify-content-center bg-danger rounded-top">
          <h3 className="mb-0 text-white">
            {title}
          </h3>
        </Modal.Header>
      )}
      <Modal.Body className="text-center m-3">
        <p className="mb-0">
          {errorToMessage(error)}
        </p>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button variant="outline-danger" onClick={() => setShow(false)}>
          Stäng
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
