import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useSidebar from 'src/hooks/useSidebar';
import SidebarNav from 'src/sidebar/SidebarNav';
import { ReactComponent as CompanyLogo } from 'src/assets/img/trygga_logo.svg';
import { ReactComponent as CompanyLogoCircle } from 'src/assets/img/trygga_circle.svg';
import sidebarNavItems from 'src/sidebar/sidebarNavItems';
import { Link } from 'react-router-dom';

export default function Sidebar () {
  const { isOpen, behavior } = useSidebar();

  return (
    <nav className={`sidebar ${!isOpen ? 'collapsed' : ''}`}>
      <div className="sidebar-content">
        <PerfectScrollbar className="border-end">
          <Link className="sidebar-brand" to="/" title="Gå till startsidan">
            {behavior === 'compact' ? (
              <CompanyLogoCircle />
            ) : (
              <CompanyLogo />
            )}
          </Link>

          <SidebarNav items={sidebarNavItems} />
        </PerfectScrollbar>
      </div>
    </nav>
  );
}
