

const settings = {
  loanClamps: {
    minYears: 3,
    maxYears: 15,
    minTotal: 20000,
    maxTotal: 200000,
  },
  hasVerifications: false,
  change: {
    changedByResubmitting: true,
  },
  accept: {
    canHaveCoApplicant: false,
    accountNoInput: true,
    accountNoRequired: true,
  },
};
export default settings;

