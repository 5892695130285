import React from 'react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import { useNavigate } from 'react-router-dom';
import { BankProcessRowWithRelations } from 'shared/types/bank_process';
import { BankRow } from 'shared/types/bank';
import {Card} from 'react-bootstrap';
import {RefreshCw} from 'react-feather';
import { BankAdapterValidatorResult } from 'shared/types/bank_adapter';
import * as api from 'src/api';
import ErrorModal from 'src/modals/ErrorModal';
import ValidationResultTooltipBox from 'src/components/ValidationResultTooltipBox';
import useAuth from 'src/hooks/useAuth';
import useNotyf from 'src/hooks/useNotyf';

interface BankValidatorResult extends BankAdapterValidatorResult {
  bank_id: string;
  bank_adapter: string;
}

interface BankProcessUncreatedCardProps {
  applicationId: string;
  bank: BankRow;
}

export default function BankProcessUncreatedCard (props: BankProcessUncreatedCardProps) {
  const { applicationId, bank } = props;

  const notyf = useNotyf();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const validateMutation = useMutation<BankValidatorResult[]>({
    mutationFn: () => api.request({
      url: `/application/${applicationId}/validateMockBankProcessesForSubmission`,
      method: 'POST',
      data: {bank_ids: [bank.id]},
    }),
  });

  const createMutation = useMutation<BankProcessRowWithRelations>({
    mutationFn: () => api.request({
      url: '/bank_process',
      method: 'POST',
      data: {
        application_id: applicationId,
        bank_id: bank.id,
      },
    }),
    onSuccess: async newBankProcess => {
      await queryClient.invalidateQueries({queryKey: [`/application/${applicationId}/process`]});
      const message = `Långivarprocessen med "${newBankProcess.Bank.name ?? newBankProcess.bank_id}" har skapats`;
      notyf.success({type: 'success', message});
      navigate(`/application/${applicationId}/bankProcess/${newBankProcess.id}/form`);
    },
  });

  const onClickValidate = async () => {
    await validateMutation.mutateAsync();
  };

  const onClickCreate = async () => {
    await createMutation.mutateAsync();
  };

  const auth = useAuth();
  const validationResult = validateMutation.data?.[0];
  const createDisabled = createMutation.isPending || (!auth.hasAnyRole('admin') && (!validationResult?.is_valid || !validationResult?.is_valid_without_co_applicant));

  return (
    <Card className="border rounded">
      <div className="bg-light card-header d-flex justify-content-between">
        <div className="card-title mb-0 flex-grow">
          <span className="d-flex align-items-center gap-2 text-secondary">
            <RefreshCw size={16} />
            {bank.name || bank.id}
          </span>
        </div>
        <div className="d-flex justify-content-end gap-1 align-items-center">
          {createMutation.error && <ErrorModal title="Ny långivarprocess" error={createMutation.error} />}
          {validateMutation.error && <ErrorModal title="Validera ny långivarprocess" error={validateMutation.error} />}
          <ValidationResultTooltipBox
            size="sm"
            validationResult={validationResult}
          />
          <ButtonSpinner
            size="sm"
            onClick={onClickValidate}
            isLoading={validateMutation.isPending}
            disabled={validateMutation.isPending}
          >
            Validera
          </ButtonSpinner>
          <ButtonSpinner
            variant="success"
            size="sm"
            isLoading={createMutation.isPending}
            disabled={createDisabled}
            onClick={onClickCreate}
          >
            Skapa
          </ButtonSpinner>
        </div>
      </div>
    </Card>
  );
}
