

const settings = {
  bankInterface: {
    index: {
      defaultFilter: {
        status: 'open',
      },
    },
  },
  loanClamps: {
    minYears: 1,
    maxYears: 6,
    minTotal: 2000,
    maxTotal: 200000,
    maxTotalClamp: false,
  },
  accept: {
    canHaveCoApplicant: false,
    changedByResubmitting: true,
  },
};
export default settings;
