import React from 'react';
import StatusBaseLabel from 'src/formatters/StatusBaseLabel';
import * as applicationTriggerFilter from 'shared/filter/application_trigger';

export function StatusLabel (props) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={value}
      formatter={applicationTriggerFilter.status}
      {...restOfProps}
    />
  );
}
