

const settings = {
  hasVerifications: true,
  loanClamps: {
    minYears: 1,
    maxYears: 10,
    minTotal: 20000,
    maxTotal: 300000,
    minTotalClamp: false,
  },
  accept: {
    canHaveCoApplicant: false,
    accountNoInput: true,
    accountNoRequired: true,
  },
  change: {
    changedByResubmitting: true,
    amountNewInput: true,
  },
};
export default settings;


