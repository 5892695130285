import React, { HTMLAttributes } from 'react';
import isObjectLike from 'lodash/isObjectLike';
import StatusBaseLabel, {StatusBaseLabelProps} from 'src/formatters/StatusBaseLabel';
import * as userFilter from 'shared/filter/user';
import avatarGeneric from 'src/assets/img/avatar_generic.png';
import classNames from 'classnames';
import { UserRow } from 'shared/types/user';

interface NameProps {
  value: Pick<UserRow, 'id' | 'name'>;
}

export function Name (props: NameProps) {
  const { value:user } = props;
  if (!isObjectLike(user)) return null;

  let name = user.name || '';
  if (!name) {
    if (user.id) {
      name = user.id;
    }
  }

  return (
    <>{name}</>
  );
}

interface StatusProps {
  value: UserRow['is_active'];
}

export function Status (props: StatusProps) {
  const { value } = props;
  return userFilter.status(value);
}

interface StatusLabelProps extends StatusBaseLabelProps {
  value: UserRow['is_active'];
}

export function StatusLabel (props: StatusLabelProps) {
  const { value:isActive, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={isActive}
      formatter={isActive => userFilter.status(isActive)}
      {...restOfProps}
    />
  );
}

interface PhotoImg extends HTMLAttributes<HTMLImageElement> {
  user?: null| Pick<UserRow, 'id' | 'name' | 'photo'>;
}

export function AvatarImg (props: PhotoImg) {
  const { user, className, ...restOfProps } = props;
  const [errorLoading, setErrorLoading] = React.useState(false);

  if (!user) {
    return (
      <img
        className={classNames(className, 'img-fluid', 'rounded-circle')}
        {...restOfProps}
        src={avatarGeneric}
      />
    );
  }

  const { id, photo, name } = user;
  const src = photo && !errorLoading ? `/api/user/${id}/photo` : avatarGeneric;

  return (
    <img
      className={classNames(className, 'img-fluid', 'rounded-circle')}
      {...restOfProps}
      src={src}
      alt={name || ''}
      onError={() => setErrorLoading(true)}
    />
  );
}
