import {useEffect, useMemo} from 'react';
import useWebsocketModel, { TModelIds } from 'src/hooks/useWebsocketModel';

type UpdateCallback<T> = (id: string, update: T) => void;

export default function useWebsocketModelUpdates<T> (modelName: string, onUpdateReceived: UpdateCallback<T>) {
  const { websocket, ids, setIds } = useWebsocketModel(modelName);

  useEffect(() => {
    websocket.methodEmitter.on('modelUpdate', data => {
      if (!isApplicableUpdate(modelName, ids, data)) return;
      const { id, update } = data;
      onUpdateReceived(id, update as T);
    });
    return () => {
      websocket.methodEmitter.off('modelUpdate');
    };
  }, [websocket.methodEmitter, ids, modelName, onUpdateReceived]);

  const hook = useMemo(() => {
    return {
      setIds: (models: TModelIds) => {
        setIds(models);
      },
    };
  }, [setIds]);

  return hook;
}

interface WebsocketModelUpdate {
  id: string;
  modelName: string;
  update: object;
}

function isApplicableUpdate (modelName: string, modelIds: string[], update: WebsocketModelUpdate) {
  if (modelName !== update.modelName) return false;
  return modelName === update.modelName && modelIds.includes(update.id);
}
