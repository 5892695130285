import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import IdShort from 'src/formatters/IdShort';
import StatusBaseLabel from 'src/formatters/StatusBaseLabel';
import * as letterFilter from 'shared/filter/letter';

export function Name (props) {
  const { value:letter } = props;
  if (!isObjectLike(letter)) return null;
  const id = letter.id;
  return (
    <IdShort value={id} />
  );
}

export function StatusLabel (props) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={value}
      formatter={letterFilter.status}
      {...restOfProps}
    />
  );
}


