import React from 'react';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import Content from 'src/ui/Content';
import { Helmet } from 'react-helmet-async';
import * as api from 'src/api';
import { ErrorAlertCardBody, CardTitleIconStatus  } from 'src/cards/CardHelpers';
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import { Card, Tab, Nav, Row, Col } from 'react-bootstrap';
import BlockSpinner from 'src/spinners/BlockSpinner';
import {useMutation, useQuery} from '@tanstack/react-query';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { CustomerJaycomInsurance } from 'shared/types/customer_jaycom_insurance';
import * as IconButtons from 'src/buttons/IconButtons';
import * as JaycomInsuranceFormatters from 'src/jaycomInsurance/JaycomInsuranceFormatters';
import {Activity, Edit2, LifeBuoy, Mail} from 'react-feather';
import JaycomInsuranceFormEditFormCard from 'src/jaycomInsurance/JaycomInsuranceFormEditFormCard';
import JaycomInsuranceLogCard from 'src/jaycomInsurance/JaycomInsuranceLogCard';
import EmailContactControlCard from 'src/email/EmailContactControlCard';
import SmsContactControlCard from 'src/sms/SmsContactControlCard';
import useNotyf from 'src/hooks/useNotyf';
import JaycomInsuranceAssociatedObjectList from 'src/jaycomInsurance/JaycomInsuranceAssociatedObjectList';
import {GuardPermission} from 'src/guards/AuthGuards';
import JaycomInsurancePropertyList from 'src/jaycomInsurance/JaycomInsurancePropertyList';
import JaycomInsuranceAdminFormCard from 'src/jaycomInsurance/JaycomInsuranceAdminFormCard';

interface CustomerJaycomInsurancePageParams {
  insuranceId: string;
  tab?: string;
}

const CustomerJaycomInsurancePage: React.FC = React.memo(function CustomerJaycomInsurancePage () {
  const { insuranceId, tab = 'form' } = useParams() as unknown as CustomerJaycomInsurancePageParams;

  const notyf = useNotyf();
  const navigate = useNavigate();

  const readQuery = useQuery<CustomerJaycomInsurance, Error>({
    queryKey: [`/customer_jaycom_insurance/${insuranceId}`],
  });

  const insurance = readQuery.data;

  const deleteMutation = useMutation<CustomerJaycomInsurance, Error>({
    mutationFn: () => api.request({
      url: `/customer_jaycom_insurance/${insuranceId}`,
      method: 'DELETE',
    }),
    onSuccess: () => {
      navigate('/customer_jaycom_insurances');
      notyf.success({type: 'danger', message: 'Försäkringen raderades'});
    },
  });

  const onClickDelete = () => {
    return deleteMutation.mutateAsync();
  };

  const reload = () => {
    readQuery.refetch();
  };

  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Försäkring (Jaycom)" />
        <h1>Försäkring (Jaycom)</h1>
        <Breadcrumbs
          crumbs={[
            ['/customer_jaycom_insurances', 'Försäkringar (Jaycom)'],
            [`/customer_jaycom_insurance/${insuranceId}/form`, insurance ? <JaycomInsuranceFormatters.Name value={insurance} /> : insuranceId],
          ]}
        />
        <BlockSpinner isLoading={readQuery.isLoading} />
        <ErrorAlertCardBody error={readQuery.error} className="mx-0 mt-0 mb-3" />
        {insurance && (
          <Card className="border">
            <Card.Header className="border-bottom bg-light">
              <CardTitleIconStatus
                as="h2"
                title={<JaycomInsuranceFormatters.Name value={insurance} />}
                Status={<JaycomInsuranceFormatters.StatusLabel value={insurance.status} className="px-3" />}
                Icon={<LifeBuoy size={16} />}
                spinning={readQuery.isRefetching}
              >
                <GuardPermission permission="customer_jaycom_insurance_delete">
                  {insurance.status === 'new' && (
                    <IconButtons.ConfirmModalDeleteButton
                      onConfirm={onClickDelete}
                      message="Är du säker på att du vill radera den här försäkringen?"
                    />
                  )}
                </GuardPermission>
                <IconButtons.InspectObjectModalButton object={insurance} />
                <IconButtons.CopyObjectIdButton id={insurance.id} />
                <IconButtons.CopyCurrentUrlButton />
                <IconButtons.RefreshObjectButton
                  disabled={readQuery.isRefetching}
                  onClick={() => readQuery.refetch()}
                />
              </CardTitleIconStatus>
            </Card.Header>
            {insurance && (
              <JaycomInsuranceAssociatedObjectList insurance={insurance} />
            )}
            <Tab.Container
              activeKey={tab}
              transition={false}
              unmountOnExit={true}
              mountOnEnter={true}
            >
              <Nav className="nav-tabs pt-3 px-3">
                <Nav.Item>
                  <NavLink className="nav-link" to={`/customer_jaycom_insurance/${insuranceId}/form`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <Edit2 size={12} />{' '}
                      Formulär
                    </span>
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" to={`/customer_jaycom_insurance/${insuranceId}/contact`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <Mail size={12} />{' '}
                      Kontakt
                    </span>
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" to={`/customer_jaycom_insurance/${insuranceId}/log`}>
                    <span className="d-inline-flex gap-1 align-items-center">
                      <Activity size={12} />{' '}
                      Logg
                    </span>
                  </NavLink>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="form">
                  <Card.Body className="pb-0">
                    <Row>
                      <Col xl={6}>
                        {insurance && (
                          <JaycomInsuranceFormEditFormCard insurance={insurance} />
                        )}
                      </Col>
                      <Col xl={6}>

                        {insurance && (
                          <Card className="border">
                            <Card.Header className="border-bottom bg-light">
                              <Card.Title className="mb-0">Egenskaper</Card.Title>
                            </Card.Header>
                            <JaycomInsurancePropertyList insurance={insurance} />
                          </Card>
                        )}

                        {insurance && (
                          <GuardPermission permission="customer_jaycom_insurance_update">
                            <JaycomInsuranceAdminFormCard
                              insurance={insurance}
                              onReload={reload}
                            />
                          </GuardPermission>
                        )}

                      </Col>
                    </Row>
                  </Card.Body>
                </Tab.Pane>
                <Tab.Pane eventKey="contact">
                  <Card.Body>
                    <Row>
                      <Col xl={6}>
                        <EmailContactControlCard
                          createPermission="email_create_customer_jaycom_insurance"
                          searchParams={{customer_jaycom_insurance_id: insuranceId}}
                          searchTemplateParams={{category: 'customer_jaycom_insurance', is_archived: 'false'}}
                          objectType="customer_jaycom_insurance"
                          objectId={insuranceId}
                        />
                      </Col>
                      <Col xl={6}>
                        <SmsContactControlCard
                          createPermission="sms_create_customer_jaycom_insurance"
                          searchParams={{customer_jaycom_insurance_id: insuranceId}}
                          searchTemplateParams={{category: 'customer_jaycom_insurance', is_archived: 'false'}}
                          objectType="customer_jaycom_insurance"
                          objectId={insuranceId}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Tab.Pane>
                <Tab.Pane eventKey="log">
                  <JaycomInsuranceLogCard insuranceId={insuranceId} className="m-4" />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card>
        )}
      </Content>
    </Main>
  );
});
export default CustomerJaycomInsurancePage;
