import React from 'react';
import * as api from 'src/api';
import BlockSpinner from 'src/spinners/BlockSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import StatusLabelContainer from 'src/components/StatusLabelContainer';
import { StatusLabel } from 'src/complaint/ComplaintFormatters';
import { useQuery } from '@tanstack/react-query';

// TODO finish

interface ComplaintQuicklookProps {
  id: string;
}

const ComplaintQuicklook: React.FC<ComplaintQuicklookProps> = React.memo(function ComplaintQuicklook (props: ComplaintQuicklookProps) {
  const { id } = props;
  const query = useQuery({
    queryKey: ['complaint', {id}],
    queryFn: () => api.request({
      url: `/complaint/${id}`,
    }),
  });

  const complaint = query?.data;

  return (
    <div className="position-relative">
      <ErrorAlert error={query.error} className="mx-3 my-0" />
      <BlockSpinner isLoading={query.isLoading} className="mx-3" center />
      {complaint && (
        <>
          <StatusLabelContainer>
            <StatusLabel value={complaint.status} />
          </StatusLabelContainer>
          <pre className="border m-3 p-3">
            {JSON.stringify(complaint, null, 2)}
          </pre>
        </>
      )}
    </div>
  );
});
export default ComplaintQuicklook;
