import React from 'react';
import { Helmet } from 'react-helmet-async';
import Content from 'src/ui/Content';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import { RefreshObjectButton } from 'src/buttons/IconButtons';
import Pluralizer from 'src/formatters/Pluralizer';
import * as fileFilter from 'shared/filter/file';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as TableCellActions from 'src/tables/TableCellActions';
import { File, Download } from 'react-feather';
import { Container, Card } from 'react-bootstrap';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { FileRow } from 'shared/types/files';
import { IColumnCellProps, IColumnDefinition, IFilterDefinition } from 'src/tables/Table';
import { IStateOrder, TableQueryKey, TStateFilterMap } from 'src/tables/types';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import CardBodyTable from 'src/tables/CardBodyTable';
import { ErrorAlertCardBody } from 'src/cards/CardHelpers';
import { useTableUpdateRowMutation } from 'src/hooks/useTableUtils';

const defaultFilter = {
  searchTerm: '',
};

const filterDefinitions: IFilterDefinition[] = [
  commonFilterDefs.string({
    id: 'searchTerm',
    title: 'Sök',
  }),
];

const columnDefinitions: IColumnDefinition[] = [
  commonColumnDefs.fileId({
    id: 'id',
    title: 'ID',
    cellProps: {rowAsObject: true},
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'description',
    title: 'Beskrivning',
  }),
  commonColumnDefs.basic({
    id: 'ext',
    title: 'Filändelse',
  }),
  commonColumnDefs.basic({
    id: 'folder',
    title: 'Mapp',
    sortable: true,
  }),
  commonColumnDefs.datetime({
    id: 'uploaded_at',
    sortable: true,
    title: 'Uppladdad',
  }),
  commonColumnDefs.basic({
    id: 'role',
    Cell: FileRoleTableCell,
    title: 'Roll',
  }),
  commonColumnDefs.basic({
    id: 'size',
    Cell: FileSizeTableCell,
    title: 'Storlek',
  }),
  commonColumnDefs.actions({}, [
    TableCellActions.InspectRowTableCellAction,
    FileDownloadActionTableCell,
    props => (
      <TableCellActions.DeleteRowTableCellAction
        {...props}
        urlTemplate="/file/%s"
        successMessage="Filen raderades"
        confirmMessage="Är du säker på att du vill radera filen?"
      />
    ),
  ]),
];

export default function FilesTablePage () {
  const [filter, setFilter] = React.useState<TStateFilterMap>(defaultFilter);
  const [order, setOrder] = React.useState<IStateOrder>({id: 'asc'});

  const queryKey: TableQueryKey = React.useMemo(() => {
    return ['/file/search', {params: {...filter, order}}];
  }, [filter, order]);

  const query = useQuery<FileRow[], Error>({
    queryKey,
    placeholderData: keepPreviousData,
  });

  const tableUpdateRowMutation = useTableUpdateRowMutation({
    queryKey,
  });

  const rows = query.data ?? [];

  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Filer" />
        <Container fluid className="p-0">
          <Card className="border mb-1">
            <Card.Header>
              <CardTitleIcon
                title="Filer"
                Icon={<File size={16} />}
                spinning={query.isLoading || query.isRefetching}
              >
                <RefreshObjectButton
                  disabled={query.isRefetching}
                  onClick={() => query.refetch()}
                />
              </CardTitleIcon>
              <Card.Subtitle className="mt-1">
                <small>
                  <Pluralizer
                    count={query.data?.length ?? 0}
                    zero="Inga filer"
                    one="Visar 1 fil"
                    otherwise="Visar %% filer"
                  />
                </small>
              </Card.Subtitle>
            </Card.Header>
            <ErrorAlertCardBody error={query.error} className="border-top p-3" />
            <CardBodyTable
              filter={filter}
              order={order}
              filterDefinitions={filterDefinitions}
              columnDefinitions={columnDefinitions}
              setFilter={setFilter}
              setOrder={setOrder}
              rows={rows}
              isFetched={query.isFetched}
              updateRowMutateAsync={tableUpdateRowMutation.mutateAsync}
              striped
            />
          </Card>
        </Container>
      </Content>
    </Main>
  );
}

function FileRoleTableCell (props: IColumnCellProps) {
  const { row } = props;
  return fileFilter.role(row.role);
}

function FileSizeTableCell (props: IColumnCellProps) {
  const { row } = props;
  if (typeof row.size !== 'number') return '';
  const size = fileFilter.size(row.size, 0);
  return (
    <>{size} KB</>
  );
}

function FileDownloadActionTableCell (props: IColumnCellProps) {
  const { row } = props;
  if (!row.uploaded_at) return null;
  const url = `/api/file/${row.id}/open`;
  return (
    <a href={url} className="btn btn-outline-primary btn-sm px-1 ms-1" target="_blank" rel="noopener noreferrer">
      <Download size={16} />
    </a>
  );
}
