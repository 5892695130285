

const settings = {
  hasVerifications: true,
  hasVerificationsInBid: false,
  loanClamps: {
    minYears: 1,
    maxYears: 15,
    minTotal: 10000,
    maxTotal: 400000,
  },
  accept: {
  },
  change: {
    amountNewInput: true,
  },
};
export default settings;
