import React from 'react';
import moment from 'moment-timezone';
import useTimezone from 'src/hooks/useTimezone';

const defaultInputFormats = [
  'YYYY-MM-DD',
  'YYYY-MM-DD HH:mm:ss',
  'YYYY-MM-DDTHH:mm:ss.SSSZ',
  'YYYY-MM-DDTHH:mm:ss.SSSSSSZ',
  'YYYY-MM-DDTHH:mm:ssZ',
];

interface DateTimeProps extends Omit<React.HTMLProps<HTMLTimeElement>, 'value'> {
  value?: string | Date | number | null;
  defaultValue?: string;
  format?: string;
}

// TODO
// 2. Toggle viewing relative or absolute time by clicking
// 2.1 Set an overlying context to toggle many DateTime components at once

export default function DateTime (props: DateTimeProps) {
  const {
    value,
    defaultValue = '',
    format = 'YYYY-MM-DD HH:mm',
    ...otherProps
  } = props;
  const timezone = useTimezone();
  const mom = moment(value, defaultInputFormats, true).tz(timezone);
  if (!mom.isValid()) return <>{defaultValue}</>;
  return (
    <time  title={mom.format('YYYY-MM-DD HH:mm:ss')} {...otherProps} dateTime={value as string}>
      {mom.format(format)}
    </time>
  );
}
