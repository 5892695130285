import React, {useState} from 'react';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import Content from 'src/ui/Content';
import { Helmet } from 'react-helmet-async';
import { useParams, NavLink, Outlet } from 'react-router-dom';
import BlockSpinner from 'src/spinners/BlockSpinner';
import useWebsocketModelUpdates from 'src/hooks/useWebsocketModelUpdates';
import * as IconButtons from 'src/buttons/IconButtons';
import {useQuery} from '@tanstack/react-query';
import {ApplicationRow} from 'shared/types/application';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { ErrorAlertCardBody, CardTitleIconStatus, CollapsibleCard  } from 'src/cards/CardHelpers';
import * as ApplicationFormatters from 'src/application/ApplicationFormatters';
import {Card, Col, Nav, Row, Tab} from 'react-bootstrap';
import {Activity, Box, Edit2, Repeat, Settings} from 'react-feather';
import {GuardPermission} from 'src/guards/AuthGuards';
import ApplicationAssociatedObjectList from 'src/application/ApplicationAssociatedObjectList';
import ApplicationLogCard from 'src/application/ApplicationLogCard';
import useQueryClientUtils from 'src/hooks/useQueryClientUtils';

interface ApplicationPageContextState {
  controlPanelOpen: false | 'application' | 'bankProcess';
}

interface ApplicationPageContextType {
  state: ApplicationPageContextState;
  setState: React.Dispatch<React.SetStateAction<ApplicationPageContextState>>;
}

export const ApplicationPageContext = React.createContext<null | ApplicationPageContextType>(null);

interface ApplicationPageParams {
  applicationId: string;
  tab?: string;
}

export default React.memo(function ApplicationPage () {
  const { applicationId, tab = 'form' } = useParams() as unknown as ApplicationPageParams;

  const queryClientUtils = useQueryClientUtils();

  const query = useQuery<ApplicationRow, Error>({
    queryKey: [`/application/${applicationId}`],
    meta: {
      onSuccess: application => {
        wsModelUpdates.setIds([application.id]);
      },
    },
  });

  const wsModelUpdates = useWebsocketModelUpdates<ApplicationRow>('application', function (id, update) {
    const row = {...update, id};
    queryClientUtils.applyUpdates({queryKey: [`/application/${applicationId}`]}, [row]);
  });

  const onClickDelete = () => {
    // return deleteMutation.mutateAsync();
  };

  const [applicationPageState, setApplicationPageState] = useState<ApplicationPageContextState>({
    controlPanelOpen: 'bankProcess',
  });

  const applicationPageContext = {
    state: applicationPageState,
    setState: setApplicationPageState,
  };

  const onToggleControlPanel = (open: boolean) => {
    applicationPageContext?.setState(prevState => ({
      ...prevState,
      controlPanelOpen: open ? 'application' : false,
    }));
  };

  const application = query.data;

  return (
    <Main>
      <Navbar />
      <Content>
        <ApplicationPageContext.Provider value={applicationPageContext}>
          <Helmet title="Ansökan" />
          <h1>Ansökan</h1>
          <Breadcrumbs
            crumbs={[
              ['/applications/all', 'Ansökningar'],
              [`/application/${applicationId}/form`, application ? <ApplicationFormatters.Title value={application} /> : applicationId],
            ]}
          />
          <BlockSpinner isLoading={query.isLoading} />
          <ErrorAlertCardBody error={query.error} className="mx-0 mt-0 mb-3" />
          {application && (
            <Card className="border">
              <Card.Header className="border-bottom bg-light">
                <CardTitleIconStatus
                  as="h2"
                  title={<ApplicationFormatters.Title value={application} />}
                  Status={<ApplicationFormatters.StatusLabel value={application.status} className="px-3" />}
                  Icon={<Box size={16} />}
                  spinning={query.isRefetching}
                >
                  <GuardPermission permission="application_delete">
                    <IconButtons.ConfirmModalDeleteButton
                      onConfirm={onClickDelete}
                      message="Är du säker på att du vill radera den här ansökan?"
                    />
                  </GuardPermission>
                  <IconButtons.FavoriteButton type="application" id={application.id} />
                  <IconButtons.InspectObjectModalButton object={application} />
                  <IconButtons.CopyObjectIdButton id={application.id} />
                  <IconButtons.CopyCurrentUrlButton />
                  <IconButtons.RefreshObjectButton
                    disabled={query.isRefetching}
                    onClick={() => query.refetch()}
                  />
                </CardTitleIconStatus>
              </Card.Header>
              {application && (
                <ApplicationAssociatedObjectList application={application} />
              )}
              <Tab.Container
                activeKey={tab}
                transition={false}
                unmountOnExit={true}
                mountOnEnter={true}
              >
                <Nav className="nav-tabs pt-3 px-3">
                  <Nav.Item>
                    <NavLink className="nav-link" to={`/application/${applicationId}/form`}>
                      <span className="d-inline-flex gap-1 align-items-center">
                        <Edit2 size={12} />{' '}
                        Formulär
                      </span>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink className="nav-link" to={`/application/${applicationId}/bankProcess`}>
                      <span className="d-inline-flex gap-1 align-items-center">
                        <Repeat size={12} />{' '}
                        Processer
                      </span>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink className="nav-link" to={`/application/${applicationId}/log`}>
                      <span className="d-inline-flex gap-1 align-items-center">
                        <Activity size={12} />{' '}
                        Logg
                      </span>
                    </NavLink>
                  </Nav.Item>
                </Nav>

                <Row className="px-4 pt-4">
                  <Col lg={applicationPageState.controlPanelOpen === 'application' ? 8 : undefined}>
                    <Tab.Content>
                      <Outlet />
                    </Tab.Content>
                  </Col>
                  <Col lg={applicationPageState.controlPanelOpen === 'application' ? 4 : 'auto'}>
                    <CollapsibleCard
                      className="border"
                      title="Kontrollpanel"
                      Icon={<Settings size={18} />}
                      open={applicationPageState.controlPanelOpen === 'application'}
                      onToggle={onToggleControlPanel}
                    >
                      <Card.Body>
                        visa kontrollpanel
                      </Card.Body>
                    </CollapsibleCard>
                  </Col>
                </Row>

              </Tab.Container>
            </Card>
          )}
        </ApplicationPageContext.Provider>
      </Content>
    </Main>
  );
});

// most tabs are exported here temporarily

export function ApplicationPageTabLog () {
  const { applicationId } = useParams() as unknown as ApplicationPageParams;
  return (
    <ApplicationLogCard applicationId={applicationId} />
  );
}

export function ApplicationPageTabForm () {
  const { applicationId } = useParams() as unknown as ApplicationPageParams;

  const readQuery = useQuery<ApplicationRow, Error>({
    queryKey: [`/application/${applicationId}`],
  });

  const application = readQuery.data;

  return (
    <Card.Body className="p-0">
      <Row>
        <Col xl={6}>
          {application && (
            <Card className="border mb-4">
              <Card.Header className="border-bottom bg-light">
                <Card.Title className="mb-0">Ansökan</Card.Title>
              </Card.Header>
              <Card.Body>Det finns ingenting här än.</Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </Card.Body>
  );
}
