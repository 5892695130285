import React from 'react';
import OverlaySpinner from 'src/spinners/OverlaySpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import { useMutation, useQuery } from '@tanstack/react-query';
import * as api from 'src/api';
import { CustomerJaycomInsurance } from 'shared/types/customer_jaycom_insurance';
import { Card, Table } from 'react-bootstrap';
import { LifeBuoy, Trash2 } from 'react-feather';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';
import * as IconButtons from 'src/buttons/IconButtons';
import JaycomInsuranceAnchor from 'src/jaycomInsurance/JaycomInsuranceAnchor';
import UserAnchor from 'src/user/UserAnchor';
import * as JaycomInsuranceFormatters from 'src/jaycomInsurance/JaycomInsuranceFormatters';
import DateTime from 'src/formatters/DateTime';
import classNames from 'classnames';
import ButtonPromiseSpinner from 'src/spinners/ButtonPromiseSpinner';
import useNotyf from 'src/hooks/useNotyf';
import {GuardPermission} from 'src/guards/AuthGuards';
import {useNavigate} from 'react-router-dom';
import ConfirmActionModalExecuteButton from 'src/buttons/ConfirmActionModalExecuteButton';

// TODO decide if we really want to use a "colored" button in the header for creation

interface JaycomInsuranceListCardProps {
  searchParams: Record<string, any>;
  createParams?: Record<string, any>;
  className?: string;
}

export default function JaycomInsuranceListCard (props: JaycomInsuranceListCardProps) {
  const { createParams, searchParams, className } = props;

  const notyf = useNotyf();
  const navigate = useNavigate();

  const query = useQuery<CustomerJaycomInsurance[], Error>({
    queryKey: ['/customer_jaycom_insurance/search', {params: searchParams}],
  });

  const createMutation = useMutation<CustomerJaycomInsurance, Error, void>({
    mutationFn: () => api.request({
      url: '/customer_jaycom_insurance',
      method: 'POST',
      data: createParams,
    }),
    onSuccess: insurance => {
      notyf.success({type: 'success', message: 'Försäkringen skapades'});
      query.refetch().then(() => {
        return navigate(`/customer_jaycom_insurance/${insurance.id}/form`);
      });
    },
  });

  const reload = () => {
    query.refetch();
  };

  const data = query.data ?? [];

  return (
    <Card className={classNames('border', className)}>
      <Card.Header className="border-bottom bg-light">
        <CardTitleIcon
          title="Försäkringar (Jaycom)"
          Icon={<LifeBuoy size={18} />}
          spinning={query.isRefetching}
        >
          <GuardPermission permission="customer_jaycom_insurance_create">
            <ButtonPromiseSpinner
              variant="outline-success"
              size="sm"
              className="py-0"
              onClick={createMutation.mutateAsync}
              title="Skapa en ny försäkring"
            >
              Ny försäkring
            </ButtonPromiseSpinner>
          </GuardPermission>
          <IconButtons.RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <div className="position-relative table-responsive">
        <ErrorAlert error={query.error} className="mx-3 mt-3 mb-2" />
        <OverlaySpinner isLoading={query.isLoading} />
        <Table striped className="my-0 border-bottom">
          <thead>
            <tr>
              <td>ID</td>
              <th>Skapad</th>
              <th>Användare</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {query.isSuccess && data.map(item => (
              <tr key={item.id}>
                <td>
                  <JaycomInsuranceAnchor insurance={item} />
                </td>
                <td><DateTime value={item.created_at} /></td>
                <td>
                  {item.created_by_user_id && (
                    <UserAnchor user={{id: item.created_by_user_id}} />
                  )}
                </td>
                <td><JaycomInsuranceFormatters.StatusLabel value={item.status} narrow short /></td>
                <td>
                  <div className="d-flex justify-content-end align-items-center gap-1">
                    <GuardPermission permission="customer_jaycom_insurance_delete">
                      {item.status === 'new' && (
                        <ConfirmActionModalExecuteButton
                          url={`/customer_jaycom_insurance/${item.id}`}
                          className="px-1"
                          method="DELETE"
                          onSuccess={reload}
                          size="sm"
                          variant="outline-danger"
                          message="Är du säker på att du vill ta bort försäkringen?"
                        >
                          <Trash2 size={14 }/>
                        </ConfirmActionModalExecuteButton>
                      )}
                    </GuardPermission>
                    <InspectObjectModalButton
                      size="sm"
                      object={item}
                    />
                  </div>
                </td>
              </tr>
            ))}
            {!query.data?.length && (
              <tr>
                <td colSpan={5} className="text-center">
                  Det finns inga Jaycom-försäkringar här
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </Card>
  );
}
