const settings = {
  bankInterface: {
    index: {
      defaultFilter: {
        status: 'open',
      },
    },
  },
  loanClamps: {
  },
  accept: {
  },
  change: {
  },
};
export default settings;
