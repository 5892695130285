import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import ChartJsDefaults from 'src/config/ChartJsDefaults';
import AppError from 'src/ui/AppError';
import { AuthProvider } from 'src/contexts/AuthContext';
import { ErrorBoundary } from 'react-error-boundary';
import * as api from 'src/api';
import Router from 'src/Router';

import { ThemeProvider } from 'src/contexts/ThemeContext';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import { LayoutProvider } from 'src/contexts/LayoutContext';
import EventEmitterContext from 'src/contexts/EventEmitterContext';

import EventEmitter from 'events';

const eventEmitter = new EventEmitter();

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
    },
    queries: {
      queryFn: async context => {
        const { queryKey } = context;
        if (typeof queryKey[0] === 'string') {
          const url = queryKey[0];
          const props = queryKey[1] ?? {};
          const result = await api.request({...props, url});
          const meta = context.meta as {onSuccess?: ((result: unknown) => unknown | Promise<unknown>);};
          if (meta?.onSuccess) {
            await meta.onSuccess?.(result);
          }
          return result;
        }
        return null;
      },
      retry: false,
      staleTime: Infinity,
      gcTime: 1000,
      refetchOnWindowFocus: false,
      retryOnMount: false,
      // onError: error => {
      //   // TODO continue here, default modal for all errors
      // },
    },
  },
});

export default function App () {
  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s - Trygga"
        defaultTitle="Trygga"
      />
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary FallbackComponent={AppError}>
          <EventEmitterContext.Provider value={eventEmitter}>
            <AuthProvider>
              <SidebarProvider>
                <ThemeProvider>
                  <LayoutProvider>
                    <ChartJsDefaults />
                    <Router />
                  </LayoutProvider>
                </ThemeProvider>
              </SidebarProvider>
            </AuthProvider>
          </EventEmitterContext.Provider>
        </ErrorBoundary>
      </QueryClientProvider>
    </HelmetProvider>
  );
}
