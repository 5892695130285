import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { HelpCircle } from 'react-feather';
import styled from 'styled-components';

const HelperTooltipWrapper = styled.span`
  cursor: help;
`;

interface HelperTooltipProps extends React.PropsWithChildren {
  size?: number;
}

export default React.memo(function HelperTooltip (props: HelperTooltipProps) {
  const { size = 18, children } = props;
  return (
    <HelperTooltipWrapper>
      <OverlayTrigger placement="auto" overlay={<Tooltip style={{position: 'fixed'}}>{children}</Tooltip>}>
        <HelpCircle size={size} />
      </OverlayTrigger>
    </HelperTooltipWrapper>
  );
});
