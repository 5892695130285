import React from 'react';
import classNames from 'classnames';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { BankAdapterValidatorResult } from 'shared/types/bank_adapter';
import validateFilters from 'shared/filter/validate';

interface ValidationResultTooltipBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  validationResult?: BankAdapterValidatorResult;
  size?: 'sm';
}

export default function ValidationResultTooltipBox (props: ValidationResultTooltipBoxProps) {
  const { validationResult, size, className:outerClassName, ...restOfProps } = props;
  if (!validationResult) return null;

  const className = classNames(outerClassName, 'btn rounded border d-flex align-items-center bg-white cursor-help', {
    'btn-sm': size === 'sm',
    'text-danger': !validationResult.is_valid && !validationResult.is_valid_without_co_applicant,
    'text-warning': !validationResult.is_valid && validationResult.is_valid_without_co_applicant,
    'text-success': validationResult.is_valid,
  });

  if (validationResult.is_valid) {
    return (
      <div {...restOfProps} className={className}>
        Giltig
      </div>
    );
  }

  return (
    <div {...restOfProps} className={className}>
      <OverlayTrigger placement="top" overlay={<Tooltip style={{position: 'fixed'}}><ValidationErrors errors={validationResult.errors} /></Tooltip>}>
        <span>
          Ogiltig
        </span>
      </OverlayTrigger>
    </div>
  );
}

interface ValidationErrorsProps {
  errors?: BankAdapterValidatorResult['errors'];
}

function ValidationErrors (props: ValidationErrorsProps) {
  const { errors } = props;
  if (!Array.isArray(errors) || !errors.length) return null;
  return (
    <ul className="list-unstyled mb-0">
      {errors.map((error, index) => (
        <li key={index}>
          <strong className="text-danger">{error.field}:</strong> {validateFilters.reason(error.reason)}
        </li>
      ))}
    </ul>
  );
}
