

const settings = {
  bankInterface: {
    index: {
      defaultFilter: {
        status: 'open',
        sub_status: 'accept',
      },
    },
  },
  // verifications are set after accept
  hasVerifications: true,
  loanClamps: {
    minYears: 2,
    maxYears: 18,
    minTotal: 20000,
    maxTotal: 500000,
    maxTotalClamp: false,
  },
  accept: {
    canSelectOffer: true,
    selectBidByKey: 'their_id',
  },
  change: {
    amountNewInput: true,
    changedByResubmitting: true,
  },
  extraActions: ['update'],
};
export default settings;
