import {FieldInputProps, FormikHelpers, useFormikContext} from 'formik';
import React, { createContext, useContext, useMemo, useState } from 'react';

interface FormikBlurSubmitContextValue {
  submitForm: (hintName?: string) => Promise<unknown>;
}

const FormikBlurSubmitContext = createContext<FormikBlurSubmitContextValue>({
  submitForm: async () => {
    console.error('No submitForm value provided via FormikBlurSubmitContext'); // eslint-disable-line no-console
  },
});

interface FormikBlurSubmitContextProviderProps extends React.PropsWithChildren {
  onSubmit: (values: any, helpers: FormikHelpers<any>) => Promise<any>;
}

export function FormikBlurSubmitContextProvider (props: FormikBlurSubmitContextProviderProps) {
  const { children, onSubmit } = props;

  const formik = useFormikContext<any>();

  const value = useMemo(() => {
    return {
      submitForm: async () => {
        await onSubmit(formik.values as any, formik);
      },
    };
  }, [onSubmit, formik]);

  return (
    <FormikBlurSubmitContext.Provider value={value}>
      {children}
    </FormikBlurSubmitContext.Provider>
  );
}

interface UseFormikBlurSubmitValue {
  isLoading: boolean;
  onBlur: (ev: React.FocusEvent<HTMLElement>) => void;
  // onChange: (ev: React.ChangeEvent<HTMLElement>) => void;
}

export function useFormikBlurSubmit (name: string, field: FieldInputProps<unknown>): UseFormikBlurSubmitValue {
  const { submitForm } = useContext(FormikBlurSubmitContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onBlur: React.FocusEventHandler = ev => {
    field.onBlur(ev);
    setIsLoading(true);
    submitForm(name).finally(() => {
      setIsLoading(false);
    });
  };

  // not sure if onChange can be used for something, when doing on change submissions on checkboxes its buggy
  // const onChange: React.ChangeEventHandler = ev => {
  //   field.onChange(ev);
  //   setTimeout(() => {
  //     setIsLoading(true);
  //     submitForm(name).finally(() => {
  //       setIsLoading(false);
  //     });
  //   }, 1);
  // };

  return {
    onBlur,
    // onChange,
    isLoading,
  };
}
