import React from 'react';
import * as api from 'src/api';
import BlockSpinner from 'src/spinners/BlockSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import StatusLabelContainer from  'src/components/StatusLabelContainer';
import { StatusLabel } from  'src/work/WorkFormatters';
import { useQuery } from '@tanstack/react-query';

interface WorkModalBodyProps {
  id: number;
}

export default function WorkModalBody (props: WorkModalBodyProps) {
  const { id } = props;

  const query = useQuery<any, Error>({
    queryKey: ['work', {id}],
    queryFn: () => api.request({url: `/work/${id}`}),
  });

  const work = query?.data;

  return (
    <div>
      <ErrorAlert error={query.error} className="m-3" />
      <BlockSpinner isLoading={query.isLoading} />
      {work && (
        <>
          <StatusLabelContainer className="mt-2">
            <StatusLabel value={work.status} />
          </StatusLabelContainer>
          <pre className="border m-3 p-3">
            {JSON.stringify(work, null, 2)}
          </pre>
        </>
      )}
    </div>
  );
}

