

const settings = {
  bankInterface: {
    index: {
      defaultFilter: {status: 'open'},
    },
  },
  loanClamps: {
    minYears: 1,
    maxYears: 15,
    minTotal: 10000,
    maxTotal: 250000,
  },
  accept: {
    accountNoInput: true,
    accountNoRequired: true,
  },
  change: {
    amountNewInput: true,
  },
};
export default settings;
